import * as yup from "yup";

export const ValidationSchema = yup.object().shape({
  parentName: yup
    .string()
    .min(3, "Mininum 3 hərfdən ibarət olmalıdır.")
    .required("Bu xana tələb olunur."),
  feedback: yup
    .string()
    .min(3, "Mininum 3 hərfdən ibarət olmalıdır.")
    .required("Bu xana tələb olunur."),
  date: yup.string().required("Bu xana tələb olunur."),
});
