import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Status = ({ receiptsModalData, updateModalState }) => {
  const { user } = useSelector((state) => state.user);
  const [isDisabledStatus, setIsDisabledStatus] = useState([]);

  const getStatus = (status) => {
    const checkStatus = isDisabledStatus.includes(status);
    if (checkStatus) return;
    updateModalState("status", status);
  };

  const getClassName = (status) =>
    receiptsModalData.status === status ? "active" : "";

  useEffect(() => {
    if (user.role === "teacher" || user.role === "admin")
      setIsDisabledStatus(["unviewed", "viewed", "confirmed", "cancelled"]);
    if (user.role === "worker") {
      const isExistPosition = user.positions.find(
        (position) => position.key === "accounting-officer"
      );

      if (isExistPosition && receiptsModalData.status !== "confirmed") {
        setIsDisabledStatus(["confirmed"]);
      } else {
        setIsDisabledStatus(["unviewed", "viewed", "confirmed", "cancelled"]);
      }
    }
  }, [user]);
  return (
    <ul className="modal-status receipt-status ">
      <li
        className={getClassName("unviewed")}
        onClick={() => getStatus("unviewed")}
      >
        Baxılmayıb
      </li>
      <li
        className={getClassName("viewed")}
        onClick={() => getStatus("viewed")}
      >
        Baxıldı
      </li>
      <li
        className={getClassName("confirmed")}
        onClick={() => getStatus("confirmed")}
      >
        Təsdiqləndi
      </li>
      <li
        className={getClassName("cancelled")}
        onClick={() => getStatus("cancelled")}
      >
        Ləğv edildi
      </li>
    </ul>
  );
};

export default Status;
