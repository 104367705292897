import React from "react";
import moment from "moment";
import { PARENT_FEEDBACK_MODAL_ACTION_TYPE } from "../../../../../redux/actions-type";
import { useDispatch } from "react-redux";
import UpdateDeleteModal from "../../../../../globalComponents/Modals/UpdateDeleteModal/UpdateDeleteModal";
import { deleteFeedbackAction } from "../../../../../redux/actions/generalfeedbackActions";

const ParentFeedbackCard = ({ data, mode, cellNumber }) => {
  console.log(data, '"dataaa');
  const dispatch = useDispatch();
  const listData = [
    {
      key: "Tələbə adı",
      value: data?.student?.fullName,
    },
    {
      key: "Rəy",
      value: data.feedback ? data.feedback : "boş",
    },
    {
      key: "Tarix",
      value: data.date
        ? `${moment(data.date).format("YYYY-MM-DD")}`
        : "boş",
    },
  ];

  const deleteItem = () => {
    dispatch(deleteFeedbackAction(data._id));
  };

  const updateItem = (modalType) => {
    const { parentName, _id, student, date, feedback } = data;

    dispatch({
      type: PARENT_FEEDBACK_MODAL_ACTION_TYPE.GET_PARENT_FEEDBACK_MODAL,
      payload: {
        data: {
          parentName,
          _id,
          student,
          date,
          feedback,
        },
        openModal: modalType !== "more" ? true : false,
      },
    });
  };
  return (
    <>
      {mode === "desktop" ? (
        <tr>
          <td>
            <div className="td-con">
              <div className="cell-number">{cellNumber}.</div>
              <div className="table-scroll-text">{data?.parentName}</div>
              <div className="right-fade"></div>
            </div>
          </td>

          <td>{data.student.fullName}</td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text">{data.feedback}</div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text">
                {data.date
                  ? moment(data.date).format("DD-MM-YYYY")
                  : ""}
              </div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <UpdateDeleteModal
              data={data}
              dataType="parent-feedback"
              updateItem={updateItem}
              deleteItem={deleteItem}
            />
          </td>
        </tr>
      ) : (
        <div className="content-box">
          <div className="left">
            <h3>{data?.parentName}</h3>
            <ul>
              {listData.map((item, index) => (
                <li key={index}>
                  <span className="type">{item.key}:</span>
                  <p>{item.value}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="right">
            <UpdateDeleteModal
              updateItem={updateItem}
              deleteItem={deleteItem}
              data={data}
              dataType="parent-feedback"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ParentFeedbackCard;
