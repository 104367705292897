import { Box } from "@mui/material";
import { ReactComponent as CloseBtn } from "../../../assets/icons/Icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { PARENT_FEEDBACK_MODAL_ACTION_TYPE } from "../../../redux/actions-type";
import InputField from "./components/Inputs/InputField";
import StudentLists from "./components/Inputs/StudentLists";
import SubmitBtn from "./components/Buttons/SubmitBtn";
import { useCallback, useEffect, useState } from "react";
import { getAllStudent } from "../../../redux/actions/studentsActions";
import { useFormik } from "formik";
import { ValidationSchema } from "./components/ValidationSchema/ValidationSchema";

const ParentFeedbackModal = () => {
  const dispatch = useDispatch();
  const { parentFeedback } = useSelector((state) => state.parentFeedback);
  const [selectedStudentName, setSelectedStudentName] = useState(null);
  const [studentNameOpen, setStudentNameOpen] = useState(false);
  const { students } = useSelector((state) => state.studentsPagination);

  const inputNameArr = ["parentName", "feedback", "date"];

  const updateModalState = (keyName, value) => {
    dispatch({
      type: PARENT_FEEDBACK_MODAL_ACTION_TYPE.GET_PARENT_FEEDBACK_MODAL,
      payload: {
        data: {
          ...parentFeedback,
          [keyName]: value,
        },
        openModal: true,
      },
    });
  };
  const closeModal = () => {
    dispatch({
      type: PARENT_FEEDBACK_MODAL_ACTION_TYPE.GET_PARENT_FEEDBACK_MODAL,
      payload: { data: {}, openModal: false },
    });
  };

  useEffect(() => {
    setSelectedStudentName(parentFeedback.student?.fullName);
  }, []);



  const studentNameDropdown = () => {
    setStudentNameOpen(!studentNameOpen);
  };
  const studentNameAddData = (item) => {
    updateModalState("student", item._id);
    setStudentNameOpen(false);
    setSelectedStudentName(item);
  };

  useEffect(() => {
    dispatch(getAllStudent());
  }, [dispatch]);

  useEffect(() => {
    if (parentFeedback?._id && students) {
      if (parentFeedback.student) {
        setSelectedStudentName(
          students?.students?.filter(
            (item) => item.fullName === parentFeedback.student.fullName
          )[0]
        );
      }
    }
  }, []);


  const formik = useFormik({
    initialValues: {
      parentName: parentFeedback.parentName ? parentFeedback.parentName : "",
      feedback:parentFeedback?.feedback ? parentFeedback?.feedback : "",
      date:parentFeedback.date ? parentFeedback?.date : ""
    },
    validationSchema: ValidationSchema,
  });
  const setInputValue = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );


  return (
    <div className="create-update-modal-con bonus-modal feedback-modal">
      <div className="create-update-modal ">
        <div className="create-update-modal-head">
          <h2>{parentFeedback._id ? "Rəy yenilə" : "Rəy yaradın"}</h2>
          <CloseBtn onClick={closeModal} />
        </div>

        <Box
          onSubmit={(e) => e.preventDefault()}
          component="form"
          sx={{
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className="create-update-modal-form">
            <StudentLists
              selectedStudentName={selectedStudentName}
              setSelectedStudentName={setSelectedStudentName}
              studentNameOpen={studentNameOpen}
              setStudentNameOpen={setStudentNameOpen}
              studentNameDropdown={studentNameDropdown}
              studentNameAddData={studentNameAddData}
              students={students}
            />
            {inputNameArr.map((name, index) => (
              <InputField
                key={index}
                inputName={name}
                updateModalState={updateModalState}
                parentData={parentFeedback}
                formik={formik}
                setInputValue={setInputValue}
              />
            ))}
          </div>
        </Box>
        {parentFeedback?._id ? (
          <SubmitBtn
            parentData={parentFeedback}
            closeModal={closeModal}
            funcType="update"
            formik={formik}
            
          />
        ) : (
          <SubmitBtn
            parentData={parentFeedback}
            closeModal={closeModal}
            funcType="create"
            formik={formik}
          />
        )}
      </div>
    </div>
  );
};

export default ParentFeedbackModal;
