import React from "react";

const WorkerMoreModal = ({ data }) => {



  let workerPositions =
    Array.isArray(data.positions) && data.positions.length > 0
      ? data.positions
          .map((position) => {
            return `${position.name}`;
          })
          .join(", ")
      : "";


      let workerProfiles =
      Array.isArray(data.profiles) && data.profiles.length > 0
        ? data.profiles
            .map((profile) => {
              return `${profile.name}`;
            })
            .join(", ")
        : "";
  return (
    <>
      <div className="more-modal-header-inform">
        <h3>
          Əməkdaşın adı: <span>{data.fullName}</span>
        </h3>
        <h3>
          Email: <span>{data?.email ? data.email : ""}</span>
        </h3>
        <h3>
          Nömrə: <span>{data?.number ? data.number : ""}</span>
        </h3>
        <h3>
          Şöbə: <span>{data?.department ? data.department : ""}</span>
        </h3>
        <h3>
          Vəzifə: <span>{workerPositions ? workerPositions : ""}</span>
        </h3>
        <h3>
          Profillər: <span>{workerProfiles ? workerProfiles : ""}</span>
        </h3>
      </div>
    </>
  );
};

export default WorkerMoreModal;
