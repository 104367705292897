import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/dashboard/check.svg";
import { ReactComponent as Xicon } from "../../../../assets/icons/dashboard/x-close.svg";
import { ReactComponent as HelpCircle } from "../../../../assets/icons/dashboard/help-circle-dashboard.svg";
import { ReactComponent as DotsIcon } from "../../../../assets/icons/dashboard/dots-horizontal-dashboard.svg";
import { ReactComponent as Students } from "../../../../assets/icons/dashboard/dashboard-students.svg";
import { ReactComponent as PassedDemo } from "../../../../assets/icons/dashboard/passedDemo.svg";
import {
  getDashboardCancelledLessonsAction,
  getDashboardConfirmedDemosCountAction,
  getDashboardConfirmedLessonsAction,
  getDashboardHeldDemosCountAction,
  getDashboardUnviewedLessonsAction,
} from "../../../../redux/actions/dashboardAction";
import DateDropdown from "../../../../globalComponents/DateDropdown/DateDropdown";
import UnviewedLessons from "../UnvlewdLessons/UnviewedLessons";
import DateRangeModal from "../../../../globalComponents/Modals/DateRangeModal/DateRangeModal";

const LessonsAmount = () => {
  const dispatch = useDispatch();
  const {
    confirmedLessonsData,
    cancelledLessonsData,
    unviewedLessonsData,
    activeStudentsAmount,
    heldDemosCount,
    confirmedDemosCount,
  } = useSelector((state) => state.dashboardData);
  const [openUnviewedLessons, setUnviewedLessons] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openDropdownCancelled, setOpenDropdownCancelled] = useState(false);
  const [openDropdownConfirmed, setOpenDropdownConfirmed] = useState(false);
  const [openDropdownHeldDemo, setOpenDropdownHeldDemo] = useState(false);
  const [openDropdownConfirmedDemo, setOpenDropdownConfirmedDemo] =
    useState(false);

  const unvieweLessonsCount =
    unviewedLessonsData?.length > 0 &&
    unviewedLessonsData.reduce((total, item) => {
      return total + item?.lessons?.length;
    }, 0);

  const applyConfirmedFilter = (startDate, endDate) => {
    dispatch(getDashboardConfirmedLessonsAction(startDate, endDate, ""));
    setOpenCalendar(false);
    setOpenDropdownCancelled(false);
    setOpenDropdownConfirmed(false);
    setOpenDropdownHeldDemo(false);
    setOpenDropdownConfirmedDemo(false);
  };
  const applyCancelledFilter = (startDate, endDate) => {
    dispatch(getDashboardCancelledLessonsAction(startDate, endDate, ""));
    setOpenCalendar(false);
    setOpenDropdownCancelled(false);
    setOpenDropdownConfirmed(false);
    setOpenDropdownHeldDemo(false);
    setOpenDropdownConfirmedDemo(false);
  };
  const applyHeldDemoFilter = (startDate, endDate) => {
    dispatch(getDashboardHeldDemosCountAction(startDate, endDate, ""));
    setOpenCalendar(false);
    setOpenDropdownCancelled(false);
    setOpenDropdownConfirmed(false);
    setOpenDropdownHeldDemo(false);
    setOpenDropdownConfirmedDemo(false);
  };
  const applyConfirmedDemoFilter = (startDate, endDate) => {
    dispatch(getDashboardConfirmedDemosCountAction(startDate, endDate, ""));
    setOpenCalendar(false);
    setOpenDropdownCancelled(false);
    setOpenDropdownConfirmed(false);
    setOpenDropdownHeldDemo(false);
    setOpenDropdownConfirmedDemo(false);
  };

  const applyFilter = (startDate, endDate) => {
    if (openDropdownConfirmed) {
      applyConfirmedFilter(startDate, endDate);
    } else if (openDropdownCancelled) {
      applyCancelledFilter(startDate, endDate);
    } else if (openDropdownHeldDemo) {
      applyHeldDemoFilter(startDate, endDate);
    } else if (openDropdownConfirmedDemo) {
      applyConfirmedDemoFilter(startDate, endDate);
    }
  };

  const applyMonthsConfirmedFilter = (option) => {
    dispatch(getDashboardConfirmedLessonsAction("", "", option.key, ""));
  };

  const applyWeeklyConfirmedFilter = (option) => {
    dispatch(getDashboardConfirmedLessonsAction("", "", "", option.key));
  };

  const applyMonthsCancelledFilter = (option) => {
    dispatch(getDashboardCancelledLessonsAction("", "", option.key, ""));
  };

  const applyWeeklyCancelledFilter = (option) => {
    dispatch(getDashboardCancelledLessonsAction("", "", "", option.key));
  };

  const applyMonthsHeldDemoFilter = (option) => {
    dispatch(getDashboardHeldDemosCountAction("", "", option.key, ""));
  };

  const applyWeeklyHeldDemoFilter = (option) => {
    dispatch(getDashboardHeldDemosCountAction("", "", "", option.key));
  };

  const applyMonthsConfirmedDemoFilter = (option) => {
    dispatch(getDashboardConfirmedDemosCountAction("", "", option.key, ""));
  };

  const applyWeeklyConfirmedDemoFilter = (option) => {
    dispatch(getDashboardConfirmedDemosCountAction("", "", "", option.key));
  };

  useEffect(() => {
    if (openDropdownCancelled) {
      setOpenDropdownConfirmed(false);
    }
  }, [openDropdownCancelled]);
  useEffect(() => {
    if (openDropdownConfirmed) {
      setOpenDropdownCancelled(false);
    }
  }, [openDropdownConfirmed]);
  useEffect(() => {
    if (openUnviewedLessons) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [openUnviewedLessons]);

  return (
    <>
      <section className="lessons-amount">
        <div className="content-box">
          <div className="left green">
            <CheckIcon />
          </div>

          <div className="right">
            <div className="top">
              <h2 className="title">Təsdiqlənmiş dərslər</h2>
              <DateDropdown
                optionType={"date"}
                calendar={true}
                setOpenCalendar={setOpenCalendar}
                openCalendar={openCalendar}
                openDropdown={openDropdownConfirmed}
                setOpenDropdown={setOpenDropdownConfirmed}
                applyMonthsFilter={applyMonthsConfirmedFilter}
                applyWeeklyFilter={applyWeeklyConfirmedFilter}
                weeklyOption={true}
              />
            </div>
            <p className="amount">
              {confirmedLessonsData ? confirmedLessonsData : 0}
            </p>
          </div>
        </div>

        <div className="content-box cancelled-lessons">
          <div className="left red">
            <Xicon />
          </div>

          <div className="right">
            <div className="top">
              <h2 className="title">Ləğv edilmiş dərslər</h2>
              <DateDropdown
                optionType={"date"}
                calendar={true}
                setOpenCalendar={setOpenCalendar}
                openCalendar={openCalendar}
                openDropdown={openDropdownCancelled}
                setOpenDropdown={setOpenDropdownCancelled}
                applyMonthsFilter={applyMonthsCancelledFilter}
                applyWeeklyFilter={applyWeeklyCancelledFilter}
                weeklyOption={true}
              />
            </div>
            <p className="amount">
              {cancelledLessonsData ? cancelledLessonsData : 0}
            </p>
          </div>
        </div>

        <div className="content-box">
          <div className="left grey">
            <HelpCircle />
          </div>

          <div className="right">
            <div className="top">
              <h2 className="title">Baxılmamış dərslər</h2>
              <DotsIcon
                onClick={() => setUnviewedLessons(!openUnviewedLessons)}
              />
            </div>
            <p className="amount">
              {unvieweLessonsCount ? unvieweLessonsCount : 0}
            </p>
          </div>
        </div>

        <div className="content-box cancelled-lessons">
          <div className="left blue">
            <Students />
          </div>

          <div className="right">
            <div className="top">
              <h2 className="title">Aktiv tələbələr</h2>
            </div>
            <p className="amount">
              {activeStudentsAmount ? activeStudentsAmount : 0}
            </p>
          </div>
        </div>
        <div className="content-box ">
          <div className="left dark-blue">
            <PassedDemo />
          </div>

          <div className="right">
            <div className="top">
              <h2 className="title">Keçirilmiş Demolar</h2>
              <DateDropdown
                optionType={"date"}
                calendar={true}
                setOpenCalendar={setOpenCalendar}
                openCalendar={openCalendar}
                openDropdown={openDropdownHeldDemo}
                setOpenDropdown={setOpenDropdownHeldDemo}
                applyMonthsFilter={applyMonthsHeldDemoFilter}
                applyWeeklyFilter={applyWeeklyHeldDemoFilter}
                weeklyOption={true}
              />
            </div>
            <p className="amount">{heldDemosCount ? heldDemosCount : 0}</p>
          </div>
        </div>
        <div className="content-box ">
          <div className="left green">
            <CheckIcon />
          </div>

          <div className="right">
            <div className="top">
              <h2 className="title">Təstiqlənmiş Demolar</h2>
              <DateDropdown
                optionType={"date"}
                calendar={true}
                setOpenCalendar={setOpenCalendar}
                openCalendar={openCalendar}
                openDropdown={openDropdownConfirmedDemo}
                setOpenDropdown={setOpenDropdownConfirmedDemo}
                applyMonthsFilter={applyMonthsConfirmedDemoFilter}
                applyWeeklyFilter={applyWeeklyConfirmedDemoFilter}
                weeklyOption={true}
              />
            </div>
            <p className="amount">
              {confirmedDemosCount ? confirmedDemosCount : 0}
            </p>
          </div>
        </div>
      </section>

      {openCalendar && (
        <DateRangeModal
          applyFilter={applyFilter}
          setOpenCalendar={setOpenCalendar}
        />
      )}
      {openUnviewedLessons && (
        <UnviewedLessons setUnviewedLessons={setUnviewedLessons} />
      )}
    </>
  );
};

export default LessonsAmount;
