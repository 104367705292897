import { RECEIPTS_ALL_ACTIONS_TYPE } from "../actions-type";

const initialState = {
  receipts: [],
  totalPages: 1,
  lastPage: "",
  loading: false,
};

export const receiptsPaginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIPTS_ALL_ACTIONS_TYPE.GET_RECEIPT_PAGINATION:
      return {
        ...state,
        ...action.payload,
      };

    case RECEIPTS_ALL_ACTIONS_TYPE.CREATE_RECEIPT:
      return {
        ...state,
        receipts: [...state.receipts, action.payload],
      };
    case RECEIPTS_ALL_ACTIONS_TYPE.UPDATE_RECEIPT:
      return {
        ...state,
        receipts: state.receipts.map((receipt) =>
          receipt._id === action.payload._id ? action.payload : receipt
        ),
      };
    case RECEIPTS_ALL_ACTIONS_TYPE.DELETE_RECEIPT:
      return {
        ...state,
        receipts: state.receipts.filter(
          (receipt) => receipt._id !== action.payload
        ),
      };
    case RECEIPTS_ALL_ACTIONS_TYPE.GET_RECEIPT_LAST_PAGE:
      return {
        ...state,
        lastPage: action.payload,
      };
    case RECEIPTS_ALL_ACTIONS_TYPE.RECEIPT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};
