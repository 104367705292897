import { DEMO_ALL_ACTIONS_TYPE } from "../actions-type";

const initialState = {
  demos: [],
  totalPages: 1,
  lastPage: "",
  loading: false,
};

export const demoPaginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEMO_ALL_ACTIONS_TYPE.GET_DEMO_PAGINATION:
      return {
        ...state,
        ...action.payload,
      };
    case DEMO_ALL_ACTIONS_TYPE.CREATE_DEMO:
      return {
        ...state,
        demos: [...state.demos, action.payload],
      };
    case DEMO_ALL_ACTIONS_TYPE.DELETE_DEMO:
      return {
        ...state,
        demos: state.demos.filter((demo) => demo._id !== action.payload),
      };
    case DEMO_ALL_ACTIONS_TYPE.UPDATE_DEMO:
      return {
        ...state,
        demos: state.demos.map((demo) =>
          demo._id === action.payload._id ? action.payload : demo
        ),
      };
    case DEMO_ALL_ACTIONS_TYPE.DEMO_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case DEMO_ALL_ACTIONS_TYPE.GET_DEMO_LAST_PAGE:
      return {
        ...state,
        lastPage: action.payload,
      };
    default:
      return state;
  }
};
