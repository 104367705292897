import { useEffect, useState } from "react";
import { ReactComponent as CloseBtn } from "../../../assets/icons/Icon.svg";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/az";
import ContentCon from "./components/ContentCon/ContentCon";
import ModalStudents from "./components/ModalStudents/ModalStudents";
import Status from "./components/Buttons/Status";
import SaveButton from "./components/Buttons/SaveButton";
import CancelReasonDrop from "./components/CancelReasonDrop/CancelReasonDrop";

const MainTeacherModal = ({ showModal, handleClose }) => {
  const { modalLesson } = useSelector((state) => state.modalLesson);
  const { user } = useSelector((state) => state.user);
  const lessonData = modalLesson?.getLesson;
  const [togggleIcon, setToggleIcon] = useState(true);
  const [updatedResultData, setUpdatedResultData] = useState({});
  const [updatedPart, setUpdatedPart] = useState();
  const showHideClassName = showModal
    ? "main-table-modal display-block"
    : "main-table-modal display-none";
  const todayDate = new Date();
  const lessonDate = lessonData && new Date(lessonData[0]?.date);
  todayDate.setHours(0, 0, 0, 0);
  lessonDate.setHours(0, 0, 0, 0);
  // const finishedLesson = moment(lessonDate).isBefore(todayDate);
  const futureLesson = moment(lessonDate).isAfter(todayDate);
  const todayLesson =
    moment(lessonDate).format("DD MM YYYY") ===
    moment(todayDate).format("DD MM YYYY");

  const [teacherDisabled, setTeacherDisabled] = useState(() => {
    if (user?.role === "teacher") {
      if (todayLesson && lessonData[0].status === "unviewed") {
        return false;
      } else {
        return true;
      }
    } else if (user?.role === "admin" || user?.role === "super-admin") {
      if (futureLesson) {
        return true;
      } else {
        return false;
      }
    }
  });

  const [isCancelled, setIsCancelled] = useState(false);
  const [cancelReasonOpen, setCancelReasonOpen] = useState(false);
  const [selectedCancelReason, setSelectedCancelReason] = useState("");

  const cancelReasonDropdown = () => {
    setCancelReasonOpen(!cancelReasonOpen);
  };

  const closeModal = () => {
    handleClose();
    setToggleIcon("");
  };

  console.log(updatedResultData.salary, "result data");
  // useEffect(() => {
  //   setUpdatedResultData({ ...lessonData[0] });
  // }, [lessonData]);

  useEffect(() => {
    if (updatedResultData) {
      setUpdatedPart({
        course: updatedResultData.course?._id,
        status: updatedResultData.status,
        teacher: updatedResultData.teacher?._id,
        students: updatedResultData.students?.map((item) => {
          return { ...item, student: item.student._id };
        }),
      });
    }
  }, [updatedResultData]);

  const cancelReasonAddData = (item) => {
    setUpdatedResultData((prevData) => ({
      ...prevData,
      cancelReason: item,
    }));
    setSelectedCancelReason(item);
    setCancelReasonOpen(false);
  };

  useEffect(() => {
    if (!updatedResultData._id) {
      setUpdatedResultData({ ...lessonData[0] });
      setSelectedCancelReason(lessonData[0].cancelReason);
    } else if (updatedResultData.status !== "cancelled") {
      setUpdatedResultData((prevData) => ({
        ...prevData,
        cancelReason: null,
      }));

      setSelectedCancelReason("");
    }
  }, [updatedResultData.status, lessonData]);

  console.log(user.role, "roleeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");

  return (
    <div className={showHideClassName}>
      <section className="main-table-modal-con">
        <div>
          <button className="close-btn" onClick={closeModal}>
            <CloseBtn />
          </button>

          <div className="table-modal-content">
            <div className="modal-header">
              <ContentCon lessonData={lessonData} name={"Müəllim"} />
              <ContentCon lessonData={lessonData} name={"Fənn"} />
              <ContentCon lessonData={lessonData} name={"Tarix"} />
              <ContentCon lessonData={lessonData} name={"Vaxt"} />
            </div>

            <ContentCon
              lessonData={lessonData}
              name={`Maaş ${
                updatedResultData?.salary?.monthly
                  ? "(aylıq)"
                  : "saatlıq (bir tələbə üçün) "
              } `}
              teacherDisabled={teacherDisabled}
              updatedResultData={updatedResultData}
              setUpdatedResultData={setUpdatedResultData}
              disabled={user.role !== "super-admin"}
            />

            {!futureLesson && (
              <>
                <ContentCon
                  lessonData={lessonData}
                  name={"Müəllim qeydi"}
                  teacherDisabled={teacherDisabled}
                  updatedResultData={updatedResultData}
                  setUpdatedResultData={setUpdatedResultData}
                />
                <ContentCon
                  lessonData={lessonData}
                  name={"Tapşırıqlar"}
                  teacherDisabled={teacherDisabled}
                  updatedResultData={updatedResultData}
                  setUpdatedResultData={setUpdatedResultData}
                />
              </>
            )}
            <div className="create-update-modal-form">
              {updatedResultData.status === "cancelled" && (
                <CancelReasonDrop
                  cancelReasonDropdown={cancelReasonDropdown}
                  selectedCancelReason={selectedCancelReason}
                  cancelReasonOpen={cancelReasonOpen}
                  setCancelReasonOpen={setCancelReasonOpen}
                  isCancelled={isCancelled}
                  setSelectedCancelReason={setSelectedCancelReason}
                  cancelReasonAddData={cancelReasonAddData}
                  disabled={updatedResultData.status !== "cancelled"}
                  updatedResultData={updatedResultData}
                  lessonData={lessonData}
                />
              )}
            </div>

            {selectedCancelReason?.key === "other" &&
              updatedResultData.status === "cancelled" && (
                <div style={{ marginTop: "20px" }}>
                  <ContentCon
                    lessonData={lessonData}
                    name={"Səbəb daxil edin"}
                    teacherDisabled={teacherDisabled}
                    updatedResultData={updatedResultData}
                    setUpdatedResultData={setUpdatedResultData}
                    // setOtherReason={setOtherReason}
                    setSelectedCancelReason={setSelectedCancelReason}
                    selectedCancelReason={selectedCancelReason}
                    cancelReasonAddData={cancelReasonAddData}
                  />
                </div>
              )}

            <ModalStudents
              teacherDisabled={teacherDisabled}
              updatedResultData={updatedResultData}
              setUpdatedResultData={setUpdatedResultData}
              setToggleIcon={setToggleIcon}
              togggleIcon={togggleIcon}
              user={user}
            />

            <div className="modal-buttons">
              <Status
                updatedResultData={updatedResultData}
                setUpdatedResultData={setUpdatedResultData}
                futureLesson={futureLesson}
                user={user}
                setIsCancelled={setIsCancelled}
              />

              {updatedResultData.status === "cancelled" ? (
                <SaveButton
                  teacherDisabled={teacherDisabled}
                  user={user}
                  updatedResultData={updatedResultData}
                  updatedPart={updatedPart}
                  handleClose={handleClose}
                  todayLesson={todayLesson}
                  mode={"desktop"}
                  selectedCancelReason={selectedCancelReason}
                  setSelectedCancelReason={setSelectedCancelReason}
                  disabled={
                    !selectedCancelReason ||
                    (selectedCancelReason?.key === "other" &&
                      selectedCancelReason.label.length < 10) ||
                    false
                  }
                />
              ) : (
                <SaveButton
                  teacherDisabled={teacherDisabled}
                  user={user}
                  updatedResultData={updatedResultData}
                  updatedPart={updatedPart}
                  handleClose={handleClose}
                  todayLesson={todayLesson}
                  mode={"desktop"}
                  selectedCancelReason={selectedCancelReason}
                />
              )}
            </div>
          </div>
        </div>

        {updatedResultData.status === "cancelled" ? (
          <SaveButton
            teacherDisabled={teacherDisabled}
            user={user}
            updatedResultData={updatedResultData}
            updatedPart={updatedPart}
            handleClose={handleClose}
            todayLesson={todayLesson}
            mode={"mobile"}
            selectedCancelReason={selectedCancelReason}
            disabled={
              selectedCancelReason.length === 0 ||
              (selectedCancelReason?.key === "other" &&
                selectedCancelReason.label.length < 10)
            }
          />
        ) : (
          <SaveButton
            teacherDisabled={teacherDisabled}
            user={user}
            updatedResultData={updatedResultData}
            updatedPart={updatedPart}
            handleClose={handleClose}
            todayLesson={todayLesson}
            mode={"mobile"}
            selectedCancelReason={selectedCancelReason}
          />
        )}
      </section>
    </div>
  );
};

export default MainTeacherModal;
