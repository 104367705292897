import axios from "axios";
import { apiRoot } from "../../apiRoot";
import { DEMO_ALL_ACTIONS_TYPE, DEMO_MODAL_ACTION_TYPE } from "../actions-type";
import { toast } from "react-toastify";
import { logoutAction } from "./auth";

const API = axios.create({
  baseURL: `${apiRoot}/demo`,
  withCredentials: true,
});

const refreshApi = axios.create({
  baseURL: `${apiRoot}/user/auth/refresh_token`,
  withCredentials: true,
});

const REGISTERAPI = axios.create({
  baseURL: `${apiRoot}/user/auth`,
  withCredentials: true,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("auth")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("auth")).AccessToken
    }`;
  }

  return req;
});

REGISTERAPI.interceptors.request.use((req) => {
  if (localStorage.getItem("auth")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("auth")).AccessToken
    }`;
  }

  return req;
});

export const setLoadingDemoAction = (loadingValue) => ({
  type: DEMO_ALL_ACTIONS_TYPE.DEMO_LOADING,
  payload: loadingValue,
});
const demoModalLoading = (loadingValue) => ({
  type: DEMO_MODAL_ACTION_TYPE.DEMO_MODAL_LOADING,
  payload: loadingValue,
});
const toastSuccess = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};
// const toastError = (message) => {
//   toast.error(message, {
//     position: "top-right",
//     autoClose: 2000,
//     toastClassName: "custom-toast",
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     theme: "colored",
//   });
// };

export const getDemoPaginationAction =
  (pageNumber, searchQuery, status = "all") =>
  async (dispatch) => {
    dispatch(setLoadingDemoAction(true));
    try {
      const { data } = await API.get(
        `/pagination/?page=${pageNumber}&searchQuery=${searchQuery}&status=${status}`
      );

      // console.log(data,"dataaa demo")
      dispatch({
        type: DEMO_ALL_ACTIONS_TYPE.GET_DEMO_LAST_PAGE,
        payload: pageNumber,
      });
      dispatch({
        type: DEMO_ALL_ACTIONS_TYPE.GET_DEMO_PAGINATION,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      const originalRequest = error.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const token = await refreshApi.get("/");
          localStorage.setItem(
            "auth",
            JSON.stringify({
              AccessToken: token.data.accesstoken,
            })
          );
          const { data } = await API.get(
            `/pagination/?page=${pageNumber}&searchQuery=${searchQuery}&status=${status}`
          );

          // console.log(data,"dataaa demo")
          dispatch({
            type: DEMO_ALL_ACTIONS_TYPE.GET_DEMO_LAST_PAGE,
            payload: pageNumber,
          });
          dispatch({
            type: DEMO_ALL_ACTIONS_TYPE.GET_DEMO_PAGINATION,
            payload: data,
          });
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            return dispatch(logoutAction());
          }
        }
      }
    } finally {
      dispatch(setLoadingDemoAction(false));
    }
  };

export const createDemoAction = (demoData) => async (dispatch) => {
  dispatch(demoModalLoading(true));
  try {
    const { data } = await API.post("/", demoData);
    dispatch(getDemoPaginationAction(data.lastPage, "", "all"));
    dispatch({ type: DEMO_ALL_ACTIONS_TYPE.CREATE_DEMO, payload: data });
    dispatch({ type: DEMO_MODAL_ACTION_TYPE.DEMO_OPEN_MODAL, payload: false });
    toastSuccess("Yeni demo yaradıldı");
  } catch (error) {
    console.log(error);
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = await refreshApi.get("/");
        localStorage.setItem(
          "auth",
          JSON.stringify({
            AccessToken: token.data.accesstoken,
          })
        );
        const { data } = await API.post("/", demoData);
        dispatch(getDemoPaginationAction(data.lastPage, "", "all"));
        dispatch({ type: DEMO_ALL_ACTIONS_TYPE.CREATE_DEMO, payload: data });
        dispatch({
          type: DEMO_MODAL_ACTION_TYPE.DEMO_OPEN_MODAL,
          payload: false,
        });
        toastSuccess("Yeni demo yaradıldı");
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          return dispatch(logoutAction());
        }
      } 
    }
  }
  finally {
    dispatch(demoModalLoading(false));
  }
};

export const updateDemoAction = (_id, demoData) => async (dispatch) => {
  dispatch(demoModalLoading(true));
  try {
    const { data } = await API.patch(`/${_id}`, demoData);
    dispatch({ type: DEMO_ALL_ACTIONS_TYPE.UPDATE_DEMO, payload: data });
    dispatch({ type: DEMO_MODAL_ACTION_TYPE.DEMO_OPEN_MODAL, payload: false });
    toastSuccess("Demo yeniləndi");
  } catch (error) {
    console.log(error);
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = await refreshApi.get("/");
        localStorage.setItem(
          "auth",
          JSON.stringify({
            AccessToken: token.data.accesstoken,
          })
        );
        const { data } = await API.patch(`/${_id}`, demoData);
        dispatch({ type: DEMO_ALL_ACTIONS_TYPE.UPDATE_DEMO, payload: data });
        dispatch({
          type: DEMO_MODAL_ACTION_TYPE.DEMO_OPEN_MODAL,
          payload: false,
        });
        toastSuccess("Demo yeniləndi");
      } catch (error) {
        if (error?.response?.status === 401) {
          return dispatch(logoutAction());
        }
      }
    }
  } finally {
    dispatch(demoModalLoading(false));
  }
};

export const deleteDemoAction =
  ({ _id }) =>
  async (dispatch) => {
    try {
      await API.delete(`/${_id}`);
      dispatch({ type: DEMO_ALL_ACTIONS_TYPE.DELETE_DEMO, payload: _id });
      toastSuccess("Demo silindi");
    } catch (error) {
      console.log(error);
      const originalRequest = error.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const token = await refreshApi.get("/");
          localStorage.setItem(
            "auth",
            JSON.stringify({
              AccessToken: token.data.accesstoken,
            })
          );
          await API.delete(`/${_id}`);

          dispatch({ type: DEMO_ALL_ACTIONS_TYPE.DELETE_DEMO, payload: _id });
          toastSuccess("Demo silindi");
        } catch (error) {
          if (error?.response?.status === 401) {
            return dispatch(logoutAction());
          }
        }
      }
    }
  };
