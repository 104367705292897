import { ReactComponent as MinusIcon } from "../../../../../assets/icons/minus-cirlce.svg";
import { useCustomHook } from "../../../../GlobalFunctions/globalFunctions";

const ProfileInput = ({ data, index, deleteClass }) => {
  const { generalProfileList } = useCustomHook();
  // const [openDropdown, setOpenDropdown] = useState(false);
  const profileName = generalProfileList.find(
    (item) => item.key === data.key
  )?.name;

  // const addPower = (selectedPower) => {
  //   const profileData = [...modalData?.profiles];
  //   profileData[index] = { ...profileData[index], power: selectedPower };
  //   updateModalState("profiles", profileData);
  //   setOpenDropdown(false);
  // };

  return (
    <li>
      <div className="top">
        {`${index + 1}. ${profileName}`}
        <div className="minus-icon-con">
          <MinusIcon
            className="minus-icon"
            onClick={() => deleteClass(index)}
          />
        </div>
      </div>
    </li>
  );
};

export default ProfileInput;
