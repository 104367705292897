import { useSelector } from "react-redux";
import SyllabusCard from "./SyllabusCard";
import { Pagination } from "antd";
import Loading from "../../../globalComponents/Loading/Loading";
const SyllabusData = ({ syllabusPageNum, getPageNumber, currentPage }) => {
  const { syllabus, totalPages, loading } = useSelector(
    (state) => state.syllabusPagination
  );

  const tableHead = ["No", "Mövzu", ""];

  console.log(syllabus, "sylllabuss data");
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <table className="details-table syllabus-table">
            <thead>
              <tr>
                {tableHead.map((head, i) => (
                  <th key={i}>{head}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {syllabus?.map((syllabus, i) => (
                <SyllabusCard
                  key={i}
                  data={syllabus}
                  mode="desktop"
                  cellNumber={i + 1 + (currentPage - 1) * 10}
                />
              ))}
            </tbody>
          </table>

          <div className="details-list-tablet syllabus-tablet">
            {syllabus?.map((syllabus, i) => (
              <SyllabusCard
                key={i}
                data={syllabus}
                mode="tablet"
                cellNumber={i + 1}
              />
            ))}
          </div>
          {totalPages > 1 && (
            <div className="pages-pagination">
              <Pagination
                current={currentPage}
                defaultCurrent={1}
                total={totalPages * 10}
                onChange={getPageNumber}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SyllabusData;
