import React, { useEffect } from "react";
import Loading from "../../../../../globalComponents/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import ParentFeedbackCard from "./ParentFeedbackCard";
import { clearSearchValue } from "../../../../../redux/actions/clearSearchValueAction";
import { getFeedbackPaginationAction } from "../../../../../redux/actions/generalfeedbackActions";
import { Pagination } from "antd";

const ParentFeedbackData = ({ feedbackPageNum, getPageNumber }) => {
  const { feedbackData, totalPages, loading } = useSelector(
    (state) => state.feedbackData
  );
  const { parentFeedbackSearchValues } = useSelector(
    (state) => state.searchValues
  );



  const dispatch = useDispatch();

  useEffect(() => {
    if (parentFeedbackSearchValues) {
      dispatch(
        getFeedbackPaginationAction(
          1,
          "",
          "",
          parentFeedbackSearchValues,
          "parent"
        )
      );
    } else {
      dispatch(getFeedbackPaginationAction(1, "", "", "", "parent"));
    }
    return () => {
      dispatch(clearSearchValue());
    };
  }, [dispatch]);

  const parentHead = [
    { id: 1, label: "Valideyn adı" },
    { id: 2, label: "Tələbə adı" },
    { id: 3, label: "Rəy" },
    { id: 4, label: "Tarix" },
    { id: 5, label: "" },
  ];
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <table className="details-table incomes-table">
            <thead>
              <tr>
                {parentHead.map((head, i) => (
                  <th key={i}>{head.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {feedbackData?.map((feedback, i) => (
                <ParentFeedbackCard
                  key={i}
                  data={feedback}
                  mode="desktop"
                  cellNumber={i + 1 + (feedbackPageNum - 1) * 10}
                />
              ))}
            </tbody>
          </table>

          <div className="details-list-tablet tablet-more-page ">
            {feedbackData?.map((feedback, i) => (
               <ParentFeedbackCard
               key={i}
               data={feedback}
               mode="tablet"
               cellNumber={i + 1 + (feedbackPageNum - 1) * 10}
             />
            ))}
          </div>

          {totalPages > 1 && (
            <div className="pages-pagination">
              <Pagination
                current={feedbackPageNum}
                defaultCurrent={1}
                total={totalPages * 10}
                onChange={getPageNumber}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ParentFeedbackData;
