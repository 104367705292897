import { DEMO_MODAL_ACTION_TYPE } from "../actions-type";

const initialState = {
  demoModalData: {
    fullName:"",
    age:"",
    sector:"",
    class:"",
    teacher:"",
    parentName:"",
    phone:"",
    date:"",
    time:"",

  },
  demoOpenModal: false,
  demoModalLoading: false,
};

export const demoModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEMO_MODAL_ACTION_TYPE.GET_DEMO_MODAL:
      return {
        ...state,
        demoModalData: action.payload.data,
        demoOpenModal: action.payload.openModal,
      };
    case DEMO_MODAL_ACTION_TYPE.DEMO_OPEN_MODAL:
      return {
        ...state,
        demoOpenModal: action.payload,
      };
    case DEMO_MODAL_ACTION_TYPE.DEMO_MODAL_LOADING:
      return {
        ...state,
        demoModalLoading: action.payload,
      };
    default:
      return state;
  }
};
