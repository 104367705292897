import React from "react";
import UpdateDeleteModal from "../../../globalComponents/Modals/UpdateDeleteModal/UpdateDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { RECEIPTS_MODAL_ACTION_TYPE } from "../../../redux/actions-type";
import { deleteReceiptAction } from "../../../redux/actions/receiptsAction";

const ReceiptsCard = ({ data, mode, cellNumber, setOpenMoreModal }) => {
  const dispatch = useDispatch();

  const { receipts, lastPage } = useSelector(
    (state) => state.receiptsPagination
  );
  const { receiptSearchValues } = useSelector((state) => state.searchValues);


  const receiptsStatus=[
   {key:"unviewed",label:"Baxılmayıb"},
   {key:"confirmed",label:"Təsdiqləndi"},
   {key:"viewed",label:"Baxıldı"},
   {key:"cancelled",label:"Ləğv edildi"}
  ]

  const status = receiptsStatus.find(
    (status) => status.key === data?.status
  )?.label;

  const listData = [
    { key: "Tələb edən", value: data?.creator?.fullName },
    {
      key: "Məhsul adı",
      value: data.productName ? data.productName : "",
    },
    {
      key: "Məhsulun sayı",
      value: data.productCount ? data.productCount : "",
    },
  ];

  const updateItem = (modalType) => {
    const {
      creator,
      productName,
      productCount,
      createdAt,
      confirmedProductCount,
      branchName,
      appointment,
      initialAmount,
      note,
      principalAmount,
      status,
      _id,
    } = data;

    dispatch({
      type: RECEIPTS_MODAL_ACTION_TYPE.GET_RECEIPTS_MODAL,
      payload: {
        data: {
          creator,
          productName,
          productCount,
          createdAt,
          confirmedProductCount,
          branchName,
          appointment,
          initialAmount,
          note,
          principalAmount,
          status,
          _id,
        },
        openModal: modalType !== "more" ? true : false,
      },
    });
  };

  const deleteItem = () => {
    const pageNumber =
      lastPage > 1 ? (receipts.length > 1 ? lastPage : lastPage - 1) : 1;
    const _id = data._id;
    const searchQuery = receiptSearchValues;
    dispatch(deleteReceiptAction({ _id, pageNumber, searchQuery }));
  };

  const openMoreModal = () => {
    updateItem("more");
    setOpenMoreModal(true);
  };
  return (
    <>
      {mode === "desktop" ? (
        <tr>
          <td>
            <div className="td-con">
              <div className="cell-number">{cellNumber}.</div>
              <div className="table-scroll-text">{data.creator?.fullName}</div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text">
                {data.productName ? data.productName : ""}
              </div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text">
                {data.productCount ? data.productCount : ""}
              </div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
            <div className="table-scroll-text">
                {data.confirmedProductCount ? data.confirmedProductCount : ""}
              </div>
              {/* <div className="table-scroll-text">{courses}</div> */}
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text ">
                {data.initialAmount ? data.initialAmount : ""}
              </div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text ">
                {data.principalAmount ? data.principalAmount : ""}
              </div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td
            style={
              data.status === "confirmed"
                ? { backgroundColor: "#d4ffbf" }
                : data.status === "viewed"
                ? { backgroundColor: "var(--tertiary-300)" }
                : data.status === "unviewed"
                ? { backgroundColor: "#d2c3fe" }
                : { backgroundColor: "var(--error-200)" }
            }
          >
            <div className="td-con student-status ">
              <div className="table-scroll-text">
                {data?.status ? status : ""}
              </div>
            
            </div>
          </td>
          <td className="more" onClick={() => openMoreModal()}>
            Ətraflı
          </td>

      

      
          <td>
            <UpdateDeleteModal
              updateItem={updateItem}
              deleteItem={deleteItem}
              data={data}
            />
          </td>
        </tr>
      ) : (
        <div className="content-box">
          <div className="left">
            <h3>{data.fullName}</h3>
            <ul>
              {listData.map((item, index) => (
                <li key={index}>
                  <span className="type">{item.key}:</span>
                  <p>{item.value}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="right">
            <UpdateDeleteModal
              updateItem={updateItem}
              deleteItem={deleteItem}
              data={data}
            />
            <span onClick={() => openMoreModal()}>Ətraflı</span>
          </div>
        </div>
      )}
    </>
  );
};

export default ReceiptsCard;
