import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/Plus.svg";
import { useDispatch } from "react-redux";
import { PARENT_FEEDBACK_MODAL_ACTION_TYPE } from "../../../../redux/actions-type";

const FeedbackHeader = () => {
  const [selectedType, setSelectedType] = useState("Teacher");
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch({
      type: PARENT_FEEDBACK_MODAL_ACTION_TYPE.GET_PARENT_FEEDBACK_MODAL,
      payload: {
        data: {},
        openModal: true,
      },
    });
  };
  const location = useLocation();
  return (
    <div className="stimulation-head">
      <div className="stimulation-head-content ">
        <ul>
          <li>
            <Link
              onClick={() => setSelectedType("Teacher")}
              to="/feedback/teacher"
              className={`data-type ${
                location.pathname === "/feedback/teacher" ? "active" : ""
              }`}
            >
              Müəllim
            </Link>
          </li>

          <li>
            <Link
              onClick={() => setSelectedType("Student")}
              to="/feedback/student"
              className={`data-type ${
                location.pathname === "/feedback/student" ? "active" : ""
              }`}
            >
              Tələbə
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setSelectedType("Parent")}
              to="/feedback/parent"
              className={`data-type ${
                location.pathname === "/feedback/parent" ? "active" : ""
              }`}
            >
              Valideyn
            </Link>
          </li>
        </ul>
        {location.pathname === "/feedback/parent" && (
          <div onClick={openModal} className="add-stimul">
            <PlusIcon />
            <h4>Əlavə et</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackHeader;
