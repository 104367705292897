import { RECEIPTS_MODAL_ACTION_TYPE } from "../actions-type";

const initalState = {
  receiptsModalData: {
    creator: "",
    productName: "",
    productCount: "",
    createdAt: "",
    confirmCount: "",
    branch: "",
    appointment: "",
    initialValue: "",
    note: "",
    mainValue: "",
  },
  receiptsOpenModal: false,
  receiptsModalLoading: false,
};

export const receiptsModalReducer = (state = initalState, action) => {
  switch (action.type) {
    case RECEIPTS_MODAL_ACTION_TYPE.GET_RECEIPTS_MODAL:
      return {
        ...state,
        receiptsModalData:action.payload.data,
        receiptsOpenModal: action.payload.openModal,
      };
    case RECEIPTS_MODAL_ACTION_TYPE.RECEIPT_OPEN_MODAL:
      return {
        ...state,
        receiptsOpenModal: action.payload,
      };
    case RECEIPTS_MODAL_ACTION_TYPE.RECEIPT_MODAL_LOADING:
      return {
        ...state,
        receiptsModalLoading: action.payload,
      };
    default:
      return state;
  }
};
