import { WORKER_ALL_ACTIONS_TYPE } from "../actions-type";

const initialState = {
  workers: [],
  loading: false,
  totalPages: 1,
  lastPage: "",
};

export const workerPaginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case WORKER_ALL_ACTIONS_TYPE.GET_WORKER:
      return {
        ...state,
        workers: action.payload,
        // loading: false,
      };
    case WORKER_ALL_ACTIONS_TYPE.WORKER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case WORKER_ALL_ACTIONS_TYPE.GET_WORKERS_PAGINATION:
      return {
        ...state,
        ...action.payload,
      };

    case WORKER_ALL_ACTIONS_TYPE.CREATE_WORKER:
      return {
        ...state,
        workers: [...state.workers, action.payload],
      };
    case WORKER_ALL_ACTIONS_TYPE.UPDATE_WORKER:
      return {
        ...state,
        workers: state.workers.map((worker) =>
          worker._id === action.payload._id ? action.payload : worker
        ),
      };
    case WORKER_ALL_ACTIONS_TYPE.DELETE_WORKER:
      return {
        ...state,
        workers: state.workers.filter(
          (worker) => worker._id !== action.payload
        ),
      };
    case WORKER_ALL_ACTIONS_TYPE.GET_WORKERS_LAST_PAGE:
      return {
        lastPage: action.payload,
      };
    default:
      return state;
  }
};
