import { PARENT_FEEDBACK_MODAL_ACTION_TYPE } from "../actions-type";

const initialState = {
  parentFeedback: {
    parentName: "",
    student: "",
    feedback: "",
    date: "",
  },
  parentOpenModal: false,
  parentModalLoading: false,
};

export const parentFeedbackModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARENT_FEEDBACK_MODAL_ACTION_TYPE.GET_PARENT_FEEDBACK_MODAL:
      return {
        ...state,
        parentFeedback: action.payload.data,
        parentOpenModal: action.payload.openModal,
      };
    case PARENT_FEEDBACK_MODAL_ACTION_TYPE.PARENT_FEEDBACK_OPEN_MODAL:
      return {
        ...state,
        parentOpenModal: action.payload,
      };
    case PARENT_FEEDBACK_MODAL_ACTION_TYPE.PARENT_FEEDBACK_MODAL_LOADING:
      return {
        ...state,
        parentModalLoading: action.payload,
      };

    default:
      return state;
  }
};
