import { useLocation } from "react-router-dom";
import React from "react";
import NavbarProfile from "./components/NavbarProfile/NavbarProfile";
import { ReactComponent as MenuMobileIcon } from "../../assets/icons/mobile-menu.svg";
import { useDispatch } from "react-redux";
import { SIDEBAR_ACTION_TYPE } from "../../redux/actions-type";
import BranchDropdown from "./components/NavbarProfile/BranchDropdown";

export const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const openSidebar = () => {
    dispatch({ type: SIDEBAR_ACTION_TYPE.SIDEBAR_OPEN_MODAL, payload: true });
  };

  const getPageTitle = (pathname) => {
    switch (pathname) {
      case "/dashboard":
        return "İdarəetmə paneli";
      case "/":
      case "/student":
        return "Davamiyyət";
      case "/courses":
        return "Fənlər";
      case "/teachers":
        return "Müəllimlər";
      case "/students":
        return "Tələbələr";
      case "/admins":
        return "Adminlər";
      case "/table":
        return "Cədvəl";
      case "/expenses":
        return "Xərclər";
      case "/salary":
        return "Əmək haqqı";
      case "/incomes":
        return "Mədaxil ";
      case "/student-panel":
        return "Cədvəl";
      case "/teacher-panel":
        return "Cədvəl";
      case "/teacher-panel/home":
        return "Ana səhifə";
      case "/teacher-panel/salary":
        return "Əmək haqqı";
      case "/stimulations":
        return "Həvəsləndirmə";
      case "/stimulations/fine":
        return "Həvəsləndirmə";
      case "/stimulations/bonus":
        return "Həvəsləndirmə";
      case "/finance":
        return "Maliyyə";
      case "/finance/expenses":
        return "Maliyyə";
      case "/finance/incomes":
        return "Maliyyə";
      case "/feedback":
        return "Rəylər";
      case "/feedback/teacher":
        return "Rəylər";
      case "/feedback/student":
        return "Rəylər";
      case "/syllabus":
        return "Sillabuslar";

      case "/temporary-table":
        return "Müvəqqəti cədvəl";
      case "/workers":
        return "Əməkdaşlar";
      case "/demo":
        return "Demo";
      case "/receipts":
        return "Alınacaqlar cədvəli";
      default:
        return "";
    }
  };

  const pageTitle = getPageTitle(location.pathname);

  return (
    <>
      <header className="main-header">
        <div className="container">
          <div className="header-content">
            <div className="header-content-container">
              <div className="header-context">
                <MenuMobileIcon onClick={openSidebar} />
                <h2>{pageTitle}</h2>
              </div>
              <div className="header-icons">
                <BranchDropdown />
                <NavbarProfile />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
