import { useDispatch, useSelector } from "react-redux";
import { WORKERS_MODAL_ACTION_TYPE } from "../../../redux/actions-type";
import UpdateDeleteModal from "../../../globalComponents/Modals/UpdateDeleteModal/UpdateDeleteModal";
import { deleteWorkerAction } from "../../../redux/actions/workerActions";

const WorkersCard = ({ data, mode, cellNumber,setOpenMoreModal }) => {
  const dispatch = useDispatch();

  const { workers, lastPage } = useSelector((state) => state.workersPagination);
  const { workerSearchValues } = useSelector((state) => state.searchValues);

  let workerPositions =
  Array.isArray(data.positions) && data.positions.length > 0
    ? data.positions
        .map((position) => {
          return `${position.name}`;
        })
        .join(", ")
    : "";


  const listData = [
    {
      key: "Email",
      value: data.email ? data.email : "boş",
      className: "email",
    },
  ];

  const updateItem = (modalType) => {
    const { fullName, email, department, positions,profiles, number, password, _id } =
      data;
    dispatch({
      type: WORKERS_MODAL_ACTION_TYPE.GET_WORKERS_MODAL,
      payload: {
        data: {
          fullName,
          email,
          password,
          department,
          positions,
          profiles,
          number,
          _id,
        },
        openModal: modalType !== "more" ? true : false,
      },
    });
  };
  const deleteItem = () => {
    const pageNumber =
      lastPage > 1 ? (workers.length > 1 ? lastPage : lastPage - 1) : 1;
    const _id = data._id;
    const searchQuery = workerSearchValues;
    dispatch(deleteWorkerAction(_id, pageNumber, searchQuery));
  };
  const openMoreModal = () => {
    updateItem("more");
    setOpenMoreModal(true);
  };

  
  return (
    <>
      {mode === "desktop" ? (
        <tr>
          <td>
            <div className="td-con">
              <div className="cell-number">{cellNumber}.</div>
              <div className="table-scroll-text">{data.fullName}</div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scrol">{data.department}</div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text">{data.number}</div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text">{workerPositions ? workerPositions : ""}</div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td className="more" onClick={() => openMoreModal()}>
            Ətraflı
          </td>
          <td>
            <UpdateDeleteModal
              updateItem={updateItem}
              deleteItem={deleteItem}
              data={data}
            />
          </td>
        </tr>
      ) : (
        <div className="content-box admin">
          <div className="left">
            <h3>{data.fullName}</h3>
            <ul>
              {listData.map((item, index) => (
                <li key={index} className={item.className}>
                  <span className="type">{item.key}:</span>
                  <p>{item.value}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="right">
            <UpdateDeleteModal
              updateItem={updateItem}
              deleteItem={deleteItem}
              data={data}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WorkersCard;
