import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSyllabusPaginationAction } from "../../redux/actions/syllabusActions";
import {
  SYLLABUS_ALL_ACTIONS_TYPE,
  SYLLABUS_MODAL_ACTION_TYPE,
} from "../../redux/actions-type";
import SyllabusData from "./components/SyllabusData";
import GlobalHead from "../../globalComponents/GlobalHead/GlobalHead";
import { toast } from "react-toastify";

const SyllabusPage = () => {
  const dispatch = useDispatch();
  const { lastPage, currentPage } = useSelector(
    (state) => state.syllabusPagination
  );
  const { syllabusSearchValues } = useSelector((state) => state.searchValues);
  const { selectedCourse } = useSelector((state) => state.syllabusCourse);
  const [syllabusPageNum, setSyllabusPageNum] = useState(1);

  console.log(syllabusPageNum, "syllabusPageNum");
  console.log(lastPage, "syllabusPageNum");

  const getPageNumber = (pageNumber) => {
    dispatch({
      type: SYLLABUS_ALL_ACTIONS_TYPE.UPDATE_SYLLABUS_CURRENT_PAGE,
      payload: pageNumber,
    });

    dispatch(
      getSyllabusPaginationAction(
        pageNumber,
        syllabusSearchValues || "",
        selectedCourse._id
      )
    );
  };

  const openModal = () => {
    if (selectedCourse) {
      dispatch({
        type: SYLLABUS_MODAL_ACTION_TYPE.GET_SYLLABUS_MODAL,
        payload: { data: {}, openModal: true },
      });
    } else {
      toast.error("İxtisas seçməlisiniz", {
        position: "top-right",
        autoClose: 2000,
        toastClassName: "custom-toast",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const searchData = (e) => {
    e.preventDefault();
    dispatch(
      getSyllabusPaginationAction(1, syllabusSearchValues, selectedCourse._id)
    );

    dispatch({
      type: SYLLABUS_ALL_ACTIONS_TYPE.UPDATE_SYLLABUS_CURRENT_PAGE,
      payload: 1,
    });
  };

  useEffect(() => {
    if (lastPage) {
      setSyllabusPageNum(lastPage);
    }
  }, [lastPage]);

  useEffect(() => {
    if (selectedCourse._id) {
      getPageNumber(1);
    }
  }, []);

  useEffect(() => {
    if (selectedCourse._id) {
      dispatch(getSyllabusPaginationAction(1, "", selectedCourse._id));
    }

    return () => {
      dispatch({
        type: SYLLABUS_ALL_ACTIONS_TYPE.RESET_SYLLABUS_PAGINATION,
      });
    };
  }, []);
  return (
    <div className="details-page teachers-page ">
      <GlobalHead
        searchData={searchData}
        openModal={openModal}
        DATA_SEARCH_VALUE={"SYLLABUS_SEARCH_VALUE"}
        dataSearchValues={syllabusSearchValues}
        statusType="syllabus"
      />

      <SyllabusData
        syllabusPageNum={syllabusPageNum}
        currentPage={currentPage}
        getPageNumber={getPageNumber}
      />
    </div>
  );
};

export default SyllabusPage;
