import React from "react";
import moment from "moment";
import "moment/locale/az";

const ReceiptsMoreModal = ({ data }) => {

    const receiptsStatus=[
        {key:"unviewed",label:"Baxılmayıb"},
        {key:"confirmed",label:"Təsdiqləndi"},
        {key:"viewed",label:"Baxıldı"},
        {key:"cancelled",label:"Ləğv edildi"}
       ]


  const status=receiptsStatus.find(item=>item?.key===data.status).label;
  return (
    <>
      <div className="more-modal-header-inform">
        <h3>
          Tələb edən: <span>{data?.creator?.fullName}</span>
        </h3>
        <h3>
          Məhsulun adı: <span>{data?.productName}</span>
        </h3>
        <h3>
            Filial : <span>{data?.branchName}</span>
        </h3>
        <h3>
          Tələb olunan məhsul sayı : <span>{data?.productCount}</span>
        </h3>
        <h3>
        Təsdiqlənən məhsul sayı : <span>{data?.confirmedProductCount}</span>
        </h3>
        <h3>
        İlkin dəyər : <span>{data?.initialAmount}</span>
        </h3>
        <h3>
        Əsas dəyər:<span>{data?.principalAmount}</span>
          </h3>
       
        <h3>
          Təyinat : <span>{data?.appointment}</span>
        </h3>

        <h3>
          Qeyd : <span>{data.note}</span>
        </h3>
        <h3>
            Status: <span>{status}</span>
        </h3>
      </div>
    </>
  );
};

export default ReceiptsMoreModal;
