import React from "react";
import UpdateDeleteModal from "../../../globalComponents/Modals/UpdateDeleteModal/UpdateDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { DEMO_MODAL_ACTION_TYPE } from "../../../redux/actions-type";
import { deleteDemoAction } from "../../../redux/actions/demoActions";
import moment from "moment";

const DemoCard = ({ data, mode, setOpenMoreModal, cellNumber }) => {
  const dispatch = useDispatch();
  const { demos, lastPage } = useSelector((state) => state.demosPagination);
  const { demosSearchValues } = useSelector((state) => state.searchValues);
  const { demoStatus } = useSelector((state) => state.demoStatus);

  const demoStatusList = [
    { key: "appointed", label: "Təyin edildi" },
    { key: "held", label: "Keçirilib" },
    { key: "notHeld", label: "Keçirilməyib" },
    { key: "confirmed", label: "Təsdiqlənib" },
    { key: "cancelled", label: "Ləğv edilib" },
  ];

  const status = demoStatusList.find(
    (item) => item?.key === data.status
  )?.label;

  const updateItem = (modalType) => {
    const {
      fullName,
      _id,
      age,
      sector,
      class: classesName,
      teacher,
      parentName,
      phone,
      date,
      time,
      status,
    } = data;

    dispatch({
      type: DEMO_MODAL_ACTION_TYPE.GET_DEMO_MODAL,
      payload: {
        data: {
          fullName,
          _id,
          age,
          sector,
          class: classesName,
          teacher,
          parentName,
          phone,
          date,
          time,
          status,
        },
        openModal: modalType !== "more" ? true : false,
      },
    });
  };

  const deleteItem = () => {
    const pageNumber =
      lastPage > 1 ? (demos.length > 1 ? lastPage : lastPage - 1) : 1;
    const _id = data._id;
    const searchQuery = demosSearchValues;
    const status = demoStatus ? demoStatus : "all";
    dispatch(deleteDemoAction({ _id, pageNumber, searchQuery, status }));
  };

  const listData = [
    { key: "Tələbə adı	", value: data.fullName },
    { key: "Tələbə yaşı	", value: data.age ? data.age : "boş" },
    { key: "Tədris dili		", value: data?.sector },
    { key: "Fənn", value: data?.class },
    { key: "Müəllim adı", value: data?.teacher?.fullName },
    { key: "Valideyn adı", value: data?.parentName },
    {
      key: "Telefon nömrəsi:",
      value: data.phone ? data.phone : "boş",
    },
    { key: "Tarix", value: data.date },
    { key: "Saat", value: data.time },
  ];

  const openMoreModal = () => {
    updateItem("more");
    setOpenMoreModal(true);
  };

  return (
    <>
      {mode === "desktop" ? (
        <tr>
          <td>
            <div className="td-con">
              <div className="cell-number">{cellNumber}.</div>
              <div className="table-scroll-text">{data.fullName}</div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text">{data.age}</div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text">{data.sector}</div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text phone">{data?.class}</div>
              <div className="right-fade"></div>
            </div>
          </td>

          <td>
            <div className="td-con">
              <div className="table-scroll-text phone">
                {data?.teacher?.fullName}
              </div>
              <div className="right-fade"></div>
            </div>
          </td>
          {/* <td>
            <div className="td-con">
              <div className="table-scroll-text phone">{data.parentName}</div>
              <div className="right-fade"></div>
            </div>
          </td> */}
          {/* <td>
            <div className="td-con">
              <div className="table-scroll-text phone">{data.phone}</div>
              <div className="right-fade"></div>
            </div>
          </td> */}
          <td>
            <div className="td-con">
              <div className="table-scroll-text phone">
                {data.date ? moment(data.date).format("DD-MM-YYYY") : ""}
              </div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text phone">{data.time}</div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td
            style={
              data.status === "appointed"
                ? { backgroundColor: "var(--neutrals-300)" }
                : data.status === "held"
                ? { backgroundColor: "var(--secondary-300)" }
                : data.status === "notHeld"
                ? { backgroundColor: "var(--tertiary-300" }
                : data.status === "confirmed"
                ? { backgroundColor: "#d4ffbf" }
                : { backgroundColor: "var(--error-200)" }
            }
            // style={
            //   data.status === "confirmed"
            //     ? { backgroundColor: "#d4ffbf" }
            //     : data.status === "viewed"
            //     ? { backgroundColor: "#d2c3fe" }
            //     : data.status === "appointed"
            //     ? { backgroundColor: "var(--neutrals-300)" }
            //     : { backgroundColor: "var(--error-200)" }
            // }
          >
            <div className="td-con student-status ">
              <div className="table-scroll-text">
                {data?.status ? status : ""}
              </div>
            </div>
          </td>
          <td className="more" onClick={() => openMoreModal()}>
            Ətraflı
          </td>
          <td>
            <UpdateDeleteModal
              updateItem={updateItem}
              deleteItem={deleteItem}
              data={data}
            />
          </td>
        </tr>
      ) : (
        <div className="content-box">
          <div className="left">
            <h3>{data.fullName}</h3>
            <ul>
              {listData.map((item, index) => (
                <li key={index}>
                  <span className="type">{item.key}:</span>
                  <p>{item.value}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="right">
            <UpdateDeleteModal
              updateItem={updateItem}
              deleteItem={deleteItem}
              data={data}
            />
            {/* <span onClick={() => openMoreModal()}>Ətraflı</span> */}
          </div>
        </div>
      )}
    </>
  );
};

export default DemoCard;
