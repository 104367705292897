import { SEARCH_VALUES_ACTION_TYPES } from "../actions-type";

const initialState = {
  teachersSearchValues: "",
  adminsSearchValues: "",
  studentSearchValues: "",
  coursesSearchValues: "",
  salariesSearchValues: "",
  bonusSearchValues: "",
  fineSearchValues: "",
  feedbackSearchValues: "",
  studentFeedbackSearchValues: "",
  parentFeedbackSearchValues: "",
  demosSearchValues: "",
  syllabusSearchValues:"",
  receiptSearchValues:"",
  workerSearchValues:""
};

export const searchValuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_VALUES_ACTION_TYPES.TEACHERS_SEARCH_VALUE:
      return {
        ...state,
        teachersSearchValues: action.payload,
        // ...action.payload
      };
    case SEARCH_VALUES_ACTION_TYPES.STUDENTS_SEARCH_VALUE:
      return {
        ...state,
        studentSearchValues: action.payload,
      };
    case SEARCH_VALUES_ACTION_TYPES.COURSES_SEARCH_VALUE:
      return {
        ...state,
        coursesSearchValues: action.payload,
      };
    case SEARCH_VALUES_ACTION_TYPES.SALARIES_SEARCH_VALUE:
      return {
        ...state,
        salariesSearchValues: action.payload,
      };
    case SEARCH_VALUES_ACTION_TYPES.BONUS_SEARCH_VALUE:
      return {
        ...state,
        bonusSearchValues: action.payload,
      };
    case SEARCH_VALUES_ACTION_TYPES.FINE_SEARCH_VALUE:
      return {
        ...state,
        fineSearchValues: action.payload,
      };
    case SEARCH_VALUES_ACTION_TYPES.FEEDBACK_SEARCH_VALUE:
      return {
        ...state,
        feedbackSearchValues: action.payload,
      };
    case SEARCH_VALUES_ACTION_TYPES.STUDENT_FEEDBACK_SEARCH_VALUE:
      return {
        ...state,
        studentFeedbackSearchValues: action.payload,
      };
    case SEARCH_VALUES_ACTION_TYPES.PARENT_FEEDBACK_SEARCH_VALUE:
      return {
        ...state,
        parentFeedbackSearchValues: action.payload,
      };
    case SEARCH_VALUES_ACTION_TYPES.ADMINS_SEARCH_VALUE:
      return {
        ...state,
        adminsSearchValues: action.payload,
      };
    case SEARCH_VALUES_ACTION_TYPES.DEMO_SEARCH_VALUE:
      return {
        ...state,
        demosSearchValues: action.payload,
      };
      case SEARCH_VALUES_ACTION_TYPES.SYLLABUS_SEARCH_VALUE:
        return{
          ...state,
          syllabusSearchValues:action.payload
        }
        case SEARCH_VALUES_ACTION_TYPES.RECEIPT_SEARCH_VALUE:
          return{
            ...state,
            receiptSearchValues:action.payload
          }
          case SEARCH_VALUES_ACTION_TYPES.WORKER_SEARCH_VALUE:
            return{
              ...state,
              workerSearchValues:action.payload
            }
    default:
      return state;
  }
};
