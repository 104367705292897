import { WORKERS_MODAL_ACTION_TYPE } from "../actions-type"

const initialState = {
    workersModalData: {
        fullName: "",
        email: "",
        password: "",
        number:"",
        department:"",
        positions:"",
        profiles:""
    },
    workersOpenModal: false,
    workersModalLoading: false,
}

export const workersModalReducer = (state=initialState, action) => {
    switch(action.type) {
        case WORKERS_MODAL_ACTION_TYPE.GET_WORKERS_MODAL:
            return {
                ...state,
                workersModalData: action.payload.data,
                workersOpenModal: action.payload.openModal
            }
            case WORKERS_MODAL_ACTION_TYPE.WORKERS_OPEN_MODAL:
                return{
                    ...state,
                    workersOpenModal:action.payload
                }
                case WORKERS_MODAL_ACTION_TYPE.WORKERS_MODAL_LOADING:
                    return {
                      ...state,
                      workersModalLoading: action.payload,
                    };
        default:
            return state
    }
}