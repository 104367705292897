import axios from "axios";

import { toast } from "react-toastify";
import { logoutAction } from "./auth";
import { apiRoot } from "../../apiRoot";
import {
  RECEIPTS_ALL_ACTIONS_TYPE,
  RECEIPTS_MODAL_ACTION_TYPE,
} from "../actions-type";

const API = axios.create({
  baseURL: `${apiRoot}/receipt`,
  withCredentials: true,
});
API.interceptors.request.use((req) => {
  if (localStorage.getItem("auth")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("auth")).AccessToken
    }`;
  }

  return req;
});

const refreshApi = axios.create({
  baseURL: `${apiRoot}/user/auth/refresh_token`,
  withCredentials: true,
});

const toastSuccess = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};
const toastError = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 2000,
    toastClassName: "custom-toast",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

const setLoadingReceiptAction = (loadingValue) => ({
  type: RECEIPTS_ALL_ACTIONS_TYPE.RECEIPT_LOADING,
  payload: loadingValue,
});

const receiptModalLoading = (loadingValue) => ({
  type: RECEIPTS_MODAL_ACTION_TYPE.RECEIPT_MODAL_LOADING,
  payload: loadingValue,
});
export const getReceiptsPaginationAction =
  (pageNumber, searchQuery) => async (dispatch) => {
    dispatch(setLoadingReceiptAction(true));
    try {
      const { data } = await API.get(
        `/pagination/?page=${pageNumber}&searchQuery=${searchQuery}`
      );
      dispatch({
        type: RECEIPTS_ALL_ACTIONS_TYPE.GET_RECEIPT_LAST_PAGE,
        payload: pageNumber,
      });
      dispatch({
        type: RECEIPTS_ALL_ACTIONS_TYPE.GET_RECEIPT_PAGINATION,
        payload: data,
      });
    } catch (error) {
      const originalRequest = error.config;
      console.log(error);
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const token = await refreshApi.get("/");
          localStorage.setItem(
            "auth",
            JSON.stringify({
              AccessToken: token?.data?.accesstoken,
            })
          );
          const { data } = await API.get(
            `/pagination/?page=${pageNumber}&searchQuery=${searchQuery}`
          );
          dispatch({
            type: RECEIPTS_ALL_ACTIONS_TYPE.GET_RECEIPT_LAST_PAGE,
            payload: pageNumber,
          });
          dispatch({
            type: RECEIPTS_ALL_ACTIONS_TYPE.GET_RECEIPT_PAGINATION,
            payload: data,
          });
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            return dispatch(logoutAction());
          }
        }
      }
    } finally {
      dispatch(setLoadingReceiptAction(false));
    }
  };

export const createReceiptAction = (newData) => async (dispatch) => {
  dispatch(setLoadingReceiptAction(true));
  try {
    const { data } = await API.post("/", newData);
    dispatch(getReceiptsPaginationAction(data.lastPage, ""));
    dispatch({
      type: RECEIPTS_MODAL_ACTION_TYPE.RECEIPT_OPEN_MODAL,
      payload: false,
    });
    toastSuccess("Yeni məhsul yaradıldı");
  } catch (error) {
    console.log(error);
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
      try {
        const token = await refreshApi.get("/");
        localStorage.setItem(
          "auth",
          JSON.stringify({
            AccessToken: token.data.accesstoken,
          })
        );

        const { data } = await API.post("/", newData);
        dispatch(getReceiptsPaginationAction(data.lastPage, ""));
        dispatch({
          type: RECEIPTS_MODAL_ACTION_TYPE.RECEIPT_OPEN_MODAL,
          payload: false,
        });

        toastSuccess("Yeni məhsul yaradıldı");
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          return dispatch(logoutAction());
        }
      }
    }
  } finally {
    dispatch(setLoadingReceiptAction(false));
  }
};

export const updateReceiptAction = (_id, newData) => async (dispatch) => {
  dispatch(receiptModalLoading(true));
  try {
    const { data } = await API.patch(`/${_id}`, newData);
    dispatch({ type: RECEIPTS_ALL_ACTIONS_TYPE.UPDATE_RECEIPT, payload: data });
    dispatch({
      type: RECEIPTS_MODAL_ACTION_TYPE.RECEIPT_OPEN_MODAL,
      payload: false,
    });
    toastSuccess("Məhsul yeniləndi");
  } catch (error) {
    const originalRequest = error.config;

    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = await refreshApi.get("/");
        localStorage.setItem(
          "auth",
          JSON.stringify({
            AccessToken: token.data.accesstoken,
          })
        );
        const { data } = await API.patch(`/${_id}`, newData);
        dispatch({
          type: RECEIPTS_ALL_ACTIONS_TYPE.UPDATE_RECEIPT,
          payload: data,
        });
        dispatch({
          type: RECEIPTS_MODAL_ACTION_TYPE.RECEIPT_OPEN_MODAL,
          payload: false,
        });
        toastSuccess("Məhsul yeniləndi");
      } catch (error) {
        if (error?.response?.status === 401) {
          return dispatch(logoutAction());
        }
      }
    }
    console.log(error);
  } finally {
    dispatch(receiptModalLoading(false));
  }
};

export const deleteReceiptAction =
  ({ _id, pageNumber, searchQuery }) =>
  async (dispatch) => {
    try {
      await API.delete(`/${_id}`);
      dispatch(getReceiptsPaginationAction(pageNumber, searchQuery));
      dispatch({
        type: RECEIPTS_ALL_ACTIONS_TYPE.DELETE_RECEIPT,
        payload: _id,
      });
      toastSuccess("Məhsul silindi");
    } catch (error) {
      const originalRequest = error.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const token = await refreshApi.get("/");
          localStorage.setItem(
            "auth",
            JSON.stringify({
              AccessToken: token.data.accesstoken,
            })
          );

          await API.delete(`/${_id}`);
          dispatch(getReceiptsPaginationAction(pageNumber, searchQuery));
          dispatch({
            type: RECEIPTS_ALL_ACTIONS_TYPE.DELETE_RECEIPT,
            payload: _id,
          });
          toastSuccess("Məhsul silindi");
        } catch (error) {
          if (error?.response?.status === 401) {
            return dispatch(logoutAction());
          }
        }
      }

      console.log(error);
      toastError(error?.response?.data.message);
    }
  };
