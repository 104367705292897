import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as MainPanelIcon } from "../../../../assets/icons/mainPanelIcon.svg";
import { ReactComponent as CoursesIcon } from "../../../../assets/icons/coursesIcon.svg";
import { ReactComponent as TeachersIcon } from "../../../../assets/icons/teachersIcon.svg";
import { ReactComponent as StudentsIcon } from "../../../../assets/icons/studentsIcon.svg";
import { ReactComponent as TableIcon } from "../../../../assets/icons/tableIcon.svg";
import { ReactComponent as FeedBacksIcon } from "../../../../assets/icons/sidebar/feedbacks-icon.svg";
import { ReactComponent as ExpensesIcon } from "../../../../assets/icons/expensenIcon.svg";
import { useCustomHook } from "../../../../globalComponents/GlobalFunctions/globalFunctions";
import { useSelector } from "react-redux";

const SidebarWorker = ({ closeSidebar }) => {
  const location = useLocation();
  const { generalProfileList } = useCustomHook();
  const [profiles, setProfiles] = useState([]);
  const { user } = useSelector((state) => state.user);

  let nav = ["/student", "/"];
  const isActiveRoute = (route) => {
    return nav.includes(route);
  };

  const feedbackNav = ["/feedback/teacher", "/feedback/student"];
  const isfeedbackRoute = (route) => {
    return feedbackNav.includes(route);
  };

  useEffect(() => {
    if (user) {
      const currentProfiles = generalProfileList.filter((item) =>
        user?.profiles?.find((userProfile) => userProfile.key === item.key)
      );

      setProfiles(currentProfiles);
    }
  }, [user]);

  console.log(profiles);
  return (
    <ul className="sidebar-nav-list">
      {profiles.map((profile, i) => (
        <li key={i}>
          <NavLink
            to={profile.path}
            onClick={closeSidebar}
            className={location.pathname === profile.path ? "active" : ""}
          >
            {profile.icon}
            {profile.name}
          </NavLink>
        </li>
      ))}

      {/* <li>
        <NavLink to="/courses" onClick={closeSidebar}>
          <CoursesIcon />
          Fənlər
        </NavLink>
      </li>
      <li>
        <NavLink to="/teachers" onClick={closeSidebar}>
          <TeachersIcon />
          Müəllimlər
        </NavLink>
      </li>
      <li>
        <NavLink to="/students" onClick={closeSidebar}>
          <StudentsIcon />
          Tələbələr
        </NavLink>
      </li>
      <li>
        <NavLink to="/table" onClick={closeSidebar}>
          <TableIcon />
          Cədvəl
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/feedback/teacher"
          onClick={closeSidebar}
          className={isfeedbackRoute(location.pathname) ? "active" : ""}
        >
          <FeedBacksIcon />
          Rəylər
        </NavLink>
      </li>
      <li>
        <NavLink to="/receipts" onClick={closeSidebar}>
          <ExpensesIcon />
          Alınacaqlar cədvəli
        </NavLink>
      </li>
      <li>
        <NavLink to="/temporary-table" onClick={closeSidebar}>
          <TableIcon />
          Müvəqqəti cədvəl
        </NavLink>
      </li> */}
    </ul>
  );
};

export default SidebarWorker;
