import React, { useEffect, useState } from "react";
import GlobalHead from "../../globalComponents/GlobalHead/GlobalHead";
import DemoData from "./components/DemoData";
import { useDispatch, useSelector } from "react-redux";
import { DEMO_MODAL_ACTION_TYPE } from "../../redux/actions-type";
import { getDemoPaginationAction } from "../../redux/actions/demoActions";

const DemoPage = () => {
  const dispatch = useDispatch();
  const { lastPage } = useSelector((state) => state.demosPagination);
  const { demosSearchValues } = useSelector((state) => state.searchValues);
  const [demoPageNum, setDemoPageNum] = useState(1);
  const { demoStatus } = useSelector((state) => state.demoStatus);

  const getPageNumber = (pageNumber) => {
    setDemoPageNum(pageNumber);
    if (demosSearchValues) {
      dispatch(
        getDemoPaginationAction(
          pageNumber,
          demosSearchValues,
          demoStatus ? (demoStatus !== "all" ? demoStatus : "all") : "all"
        )
      );
    } else {
      dispatch(
        getDemoPaginationAction(
          pageNumber,
          "",
          demoStatus ? (demoStatus !== "all" ? demoStatus : "all") : "all"
        )
      );
    }
  };

  const searchData = (e) => {
    e.preventDefault();
    dispatch(
      getDemoPaginationAction(
        1,
        demosSearchValues,
        demoStatus ? (demoStatus !== "all" ? demoStatus : "all") : "all"
      )
    );
    setDemoPageNum(1);
  };

  useEffect(() => {
    if (lastPage) {
      setDemoPageNum(lastPage);
    }
  }, [lastPage]);
  useEffect(() => {
    if (demoStatus) {
      getPageNumber(1);
    }
  }, [demoStatus]);

  useEffect(() => {
    if (demosSearchValues) {
      dispatch(getDemoPaginationAction(1, demosSearchValues, "all"));
    } else {
      dispatch(getDemoPaginationAction(1, "", "all"));
    }
  }, [dispatch]);

  const openModal = () => {
    dispatch({
      type: DEMO_MODAL_ACTION_TYPE.GET_DEMO_MODAL,
      payload: { data: {}, openModal: true },
    });
  };
  return (
    <div className="details-page demo-page">
      <GlobalHead
        searchData={searchData}
        DATA_SEARCH_VALUE={"DEMO_SEARCH_VALUE"}
        openModal={openModal}
        dataSearchValues={demosSearchValues}
        statusType="demo"
      />
      <DemoData getPageNumber={getPageNumber} demoPageNum={demoPageNum} />
    </div>
  );
};

export default DemoPage;
