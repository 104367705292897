import { useDispatch, useSelector } from "react-redux";
import { SYLLABUS_MODAL_ACTION_TYPE } from "../../../redux/actions-type";
import UpdateDeleteModal from "../../../globalComponents/Modals/UpdateDeleteModal/UpdateDeleteModal";
import { deleteSyllabusAction } from "../../../redux/actions/syllabusActions";
const SyllabusCard = ({ data, mode, cellNumber }) => {
  const dispatch = useDispatch();

  const { syllabus, lastPage, currentPage } = useSelector(
    (state) => state.syllabusPagination
  );
  const { syllabusSearchValues } = useSelector((state) => state.searchValues);
  const { selectedCourse } = useSelector((state) => state.syllabusCourse);

  const updateItem = (modalType) => {
    const { orderNumber, name, _id } = data;
    dispatch({
      type: SYLLABUS_MODAL_ACTION_TYPE.GET_SYLLABUS_MODAL,
      payload: {
        data: {
          orderNumber,
          name,
          _id,
        },
        openModal: modalType !== "more" ? true : false,
      },
    });
  };

  const deleteItem = () => {
    const pageNumber =
      currentPage > 1
        ? syllabus.length > 1
          ? currentPage
          : currentPage - 1
        : 1;
    const _id = data._id;
    const searchQuery = syllabusSearchValues;
    const courseId = selectedCourse._id;
    dispatch(deleteSyllabusAction(_id, pageNumber, searchQuery, courseId));
  };

  const doubleClick = () => {
    updateItem("");
  };
  return (
    <>
      {mode === "desktop" ? (
        <tr onDoubleClick={doubleClick}>
          <td>
            <div className="td-con">
              <div className="table-scroll-text">{data.orderNumber}</div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text">{data.name}</div>
              <div className="right-fade"></div>
            </div>
          </td>

          <td>
            <UpdateDeleteModal
              updateItem={updateItem}
              deleteItem={deleteItem}
              data={data}
            />
          </td>
        </tr>
      ) : (
        <div className="content-box">
          <div className="left">
            {/* <h3>{data.fullName}</h3> */}
            <ul>
              <li>
                <span>No:</span>
                <p>{data.orderNumber ? data.orderNumber : "boş"}</p>
              </li>
              <li>
                <span>Mövzu:</span>
                <p>{data.name ? data.name : "boş"}</p>
              </li>
            </ul>
          </div>
          <div className="right">
            <UpdateDeleteModal
              updateItem={updateItem}
              deleteItem={deleteItem}
              data={data}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SyllabusCard;
