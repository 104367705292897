import React, { useEffect, useState } from "react";
import GlobalHead from "../../globalComponents/GlobalHead/GlobalHead";
import ReceiptsData from "./components/ReceiptsData";
import { useDispatch, useSelector } from "react-redux";
import { getReceiptsPaginationAction } from "../../redux/actions/receiptsAction";
import { RECEIPTS_MODAL_ACTION_TYPE } from "../../redux/actions-type";

const ReceiptsPage = () => {
  const { lastPage } = useSelector((state) => state.receiptsPagination);
  const { receiptSearchValues } = useSelector((state) => state.searchValues);
  const [receiptPageNum, setReceiptPageNum] = useState(1);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();


  const getPageNumber = (pageNumber) => {
    setReceiptPageNum(pageNumber);
    if (receiptSearchValues) {
      dispatch(getReceiptsPaginationAction(pageNumber, receiptSearchValues));
    } else {
      dispatch(getReceiptsPaginationAction(pageNumber, ""));
    }
  };
  const openModal = () => {
    dispatch({
      type: RECEIPTS_MODAL_ACTION_TYPE.GET_RECEIPTS_MODAL,
      payload: { data: { creator: user || "" }, openModal: true },
    });
  };
  const searchData = (e) => {
    e.preventDefault();
    dispatch(getReceiptsPaginationAction(1, receiptSearchValues));
    setReceiptPageNum(1);
  };

  useEffect(() => {
    if (lastPage) {
      setReceiptPageNum(lastPage);
    }
  }, [lastPage]);

  useEffect(() => {
    if (receiptSearchValues) {
      dispatch(getReceiptsPaginationAction(1, receiptSearchValues));
    } else {
      dispatch(getReceiptsPaginationAction(1, ""));
    }
  }, [dispatch]);

  return (
    <div>
      <GlobalHead
        searchData={searchData}
        openModal={openModal}
        DATA_SEARCH_VALUE={"RECEIPT_SEARCH_VALUE"}
        dataSearchValues={receiptSearchValues}
        statusType="receipt"
      />
      <ReceiptsData
        receiptPageNum={receiptPageNum}
        getPageNumber={getPageNumber}
      />
    </div>
  );
};

export default ReceiptsPage;
