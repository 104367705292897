import axios from "axios";
import {
  WORKER_ALL_ACTIONS_TYPE,
  WORKERS_MODAL_ACTION_TYPE,
} from "../actions-type";
import { toast } from "react-toastify";
import { logoutAction } from "./auth";
import { apiRoot } from "../../apiRoot";

const API = axios.create({
  baseURL: `${apiRoot}/user/worker`,
  withCredentials: true,
});

const refreshApi = axios.create({
  baseURL: `${apiRoot}/user/auth/refresh_token`,
  withCredentials: true,
});

const REGISTERAPI = axios.create({
  baseURL: `${apiRoot}/user/auth`,
  withCredentials: true,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("auth")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("auth")).AccessToken
    }`;
  }

  return req;
});

REGISTERAPI.interceptors.request.use((req) => {
  if (localStorage.getItem("auth")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("auth")).AccessToken
    }`;
  }

  return req;
});

const pageLoading = (loadingValue) => ({
  type: WORKER_ALL_ACTIONS_TYPE.WORKER_LOADING,
  payload: loadingValue,
});
const modalLoading = (loadingValue) => ({
  type: WORKERS_MODAL_ACTION_TYPE.WORKERS_MODAL_LOADING,
  payload: loadingValue,
});

const toastSuccess = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};
const toastError = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 2000,
    toastClassName: "custom-toast",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export const getWorkersAction = () => async (dispatch) => {
  dispatch(pageLoading(true));
  try {
    const { data } = await API.get("/");
    console.log(data);
    dispatch({ type: WORKER_ALL_ACTIONS_TYPE.GET_WORKER, payload: data });
  } catch (error) {
    console.log(error);
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = await refreshApi.get("/");
        localStorage.setItem(
          "auth",
          JSON.stringify({
            AccessToken: token.data.accesstoken,
          })
        );

        const { data } = await API.get("/");
        dispatch({ type: WORKER_ALL_ACTIONS_TYPE.GET_WORKER, payload: data });
      } catch (error) {
        if (error?.response?.status === 401) {
          return dispatch(logoutAction());
        }
        console.log(error);
      }
    }
  } finally {
    dispatch(pageLoading(false));
  }
};

export const getWorkersPaginationAction =
  (pageNumber, searchQuery) => async (dispatch) => {
    dispatch(pageLoading(true));
    try {
      const { data } = await API.get(
        `/pagination/?page=${pageNumber}&searchQuery=${searchQuery}`
      );
      dispatch({
        type: WORKER_ALL_ACTIONS_TYPE.GET_WORKERS_LAST_PAGE,
        payload: pageNumber,
      });
      dispatch({
        type: WORKER_ALL_ACTIONS_TYPE.GET_WORKERS_PAGINATION,
        payload: data,
      });
    } catch (error) {
      const originalRequest = error.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const token = await refreshApi.get("/");
          localStorage.setItem(
            "auth",
            JSON.stringify({
              AccessToken: token.data.accesstoken,
            })
          );
          const { data } = await API.get(
            `/pagination/?page=${pageNumber}&searchQuery=${searchQuery}`
          );
          dispatch({
            type: WORKER_ALL_ACTIONS_TYPE.GET_WORKERS_LAST_PAGE,
            payload: pageNumber,
          });
          dispatch({
            type: WORKER_ALL_ACTIONS_TYPE.GET_WORKERS_PAGINATION,
            payload: data,
          });
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            return dispatch(logoutAction());
          }
        }
      }
      if (error?.response?.status === 403) {
        dispatch(logoutAction());
      }
    } finally {
      dispatch(pageLoading(false));
    }
  };
export const createWorkerAction = (workerData) => async (dispatch) => {
  dispatch(modalLoading(true));
  try {
    const { data } = await REGISTERAPI.post("/worker/sign", workerData);
    dispatch(getWorkersPaginationAction(data.lastPage, ""));
    dispatch({
      type: WORKERS_MODAL_ACTION_TYPE.WORKERS_OPEN_MODAL,
      payload: false,
    });
    toastSuccess("Yeni admin yaradıldı");
  } catch (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = await refreshApi.get("/");
        localStorage.setItem(
          "auth",
          JSON.stringify({
            AccessToken: token.data.accesstoken,
          })
        );
        const { data } = await REGISTERAPI.post("/worker/sign", workerData);
        dispatch(getWorkersPaginationAction(data.lastPage, ""));

        dispatch({
          type: WORKERS_MODAL_ACTION_TYPE.WORKERS_OPEN_MODAL,
          payload: false,
        });
      } catch (error) {
        if (error?.response?.status === 401) {
          return dispatch(logoutAction());
        }
        console.log(error);
      }
    }

    if (error?.response?.data?.key === "email-already-exist") {
      toastError("Bu email ilə istifadəçi mövcuddur");
    }
    console.log(error);
  } finally {
    dispatch(modalLoading(false));
  }
};

export const updateWorkerAction = (_id, workerData) => async (dispatch) => {
  dispatch(modalLoading(true));
  try {
    const { data } = await API.patch(`/${_id}`, workerData);
    dispatch({ type: WORKER_ALL_ACTIONS_TYPE.UPDATE_WORKER, payload: data });
    dispatch({
      type: WORKERS_MODAL_ACTION_TYPE.WORKERS_OPEN_MODAL,
      payload: false,
    });
    toastSuccess("Əməkdaş yeniləndi");
  } catch (error) {
    const originalRequest = error.config;

    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = await refreshApi.get("/");
        localStorage.setItem(
          "auth",
          JSON.stringify({
            AccessToken: token.data.accesstoken,
          })
        );
        const { data } = await API.patch(`/${_id}`, workerData);
        dispatch({
          type: WORKER_ALL_ACTIONS_TYPE.UPDATE_WORKER,
          payload: data,
        });
      } catch (error) {
        if (error?.response?.status === 401) {
          return dispatch(logoutAction());
        }
        console.log(error);
      }
    }

    if (error?.response?.data?.key === "email-already-exist") {
      dispatch({
        type: WORKERS_MODAL_ACTION_TYPE.WORKERS_OPEN_MODAL,
        payload: true,
      });
      toastError("Bu email ilə istifadəçi mövcuddur");
    }
    console.log(error);
  } finally {
    dispatch(modalLoading(false));
  }
};

export const deleteWorkerAction =
  (_id, pageNumber, searchQuery) => async (dispatch) => {
    try {
      await API.delete(`/${_id}`);
      dispatch(getWorkersPaginationAction(pageNumber, searchQuery));

      dispatch({ type: WORKER_ALL_ACTIONS_TYPE.DELETE_WORKER, payload: _id });
      toastSuccess("Əməkdaş silindi");
    } catch (error) {
            console.log(error);

      const originalRequest = error.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const token = await refreshApi.get("/");
          localStorage.setItem(
            "auth",
            JSON.stringify({
              AccessToken: token.data.accesstoken,
            })
          );

          await API.delete(`/${_id}`);
          dispatch(getWorkersPaginationAction(pageNumber, searchQuery));
          dispatch({
            type: WORKER_ALL_ACTIONS_TYPE.DELETE_WORKER,
            payload: _id,
          });
          toastSuccess("Admin silindi");
        } catch (error) {
          if (error?.response?.status === 401) {
            return dispatch(logoutAction());
          }
        }
      }
      toastError(error?.response?.data.message);
    }
  };
