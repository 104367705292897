import { useDispatch, useSelector } from "react-redux";
import { SEARCH_VALUES_ACTION_TYPES } from "../../../../../redux/actions-type";
import LoadingBtn from "../../../../Loading/components/LoadingBtn/LoadingBtn";
import {
  createParentFeedbackAction,
  updateParentFeedbackAction,
} from "../../../../../redux/actions/generalfeedbackActions";
import { useEffect, useState } from "react";

const SubmitBtn = ({ parentData, funcType, formik }) => {
  const dispatch = useDispatch();
  const { parentModalLoading } = useSelector((state) => state.parentFeedback);

  const [isDisabled, setIsDisabled] = useState(() => {
    if (funcType === "update") {
      return false;
    } else {
      return true;
    }
  });

  useEffect(() => {
    setIsDisabled(() => {
      if (funcType === "update") {
        if (Object.keys(formik.errors).length === 0 && parentData?.parentName) {
          return false;
        } else if (
          Object.keys(formik.errors).length === 1 &&
          formik.errors.password === "Bu xana tələb olunur."
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (formik.isValid && parentData?.parentName) {
          return false;
        } else {
          return true;
        }
      }
    });
  }, [formik.errors]);

  const demoCreate = () => {
    if (parentData?._id) {
      dispatch(updateParentFeedbackAction(parentData?._id, parentData));
    } else {
      dispatch({
        type: SEARCH_VALUES_ACTION_TYPES.PARENT_FEEDBACK_SEARCH_VALUE,
        payload: "",
      });
      dispatch(
        createParentFeedbackAction({
          ...parentData,
        })
      );
    }
  };

  return (
    // <div>
    <div className="create-update-modal-btn demo-btn ">
      <button disabled={parentModalLoading || isDisabled} onClick={demoCreate}>
        {parentModalLoading ? (
          <LoadingBtn />
        ) : funcType === "update" ? (
          "Yenilə"
        ) : (
          "Yarat"
        )}
      </button>
    </div>
    // </div>
  );
};

export default SubmitBtn;
