import { ReactComponent as CloseBtn } from "../../../assets/icons/Icon.svg";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DEMO_MODAL_ACTION_TYPE } from "../../../redux/actions-type";
import InputField from "./components/Inputs/InputField";
import RadioInput from "./components/Inputs/RadioInput";
import CoursesInput from "./components/Inputs/CoursesInput";
import { useCallback, useEffect, useState } from "react";
import SubmitBtn from "./components/Buttons/SubmitBtn";
import Status from "./components/Buttons/Status";
import { useFormik } from "formik";
import { ValidationSchema } from "./components/ValidationSchema/ValidationSchema";
import { allCoursesAction } from "../../../redux/actions/coursesActions";
import TeacherLists from "./components/Inputs/TeachersInput";
import { getTeachersAction } from "../../../redux/actions/teachersActions";
const DemoModal = () => {
  const { demoModalData } = useSelector((state) => state.demoModal);
  const dispatch = useDispatch();
  const [selectedClassName, setSelectedClassName] = useState("");
  const [classNameOpen, setClassNameOpen] = useState(false);
  const { allCourses } = useSelector((state) => state.allCourses);
  const { teachers } = useSelector((state) => state.teachersPagination);
  const [selectedTeacherName, setSelectedTeacherName] = useState("");
  const [teacherNameOpen, setTeacherNameOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [statusOpen, setStatusOpen] = useState(false);

  const statusList = [
    { status: "appointed", label: "Təyin edildi" },
    { status: "held", label: "Keçirilib" },
    { status: "notHeld", label: "Keçirilməyib" },
    { status: "confirmed", label: "Təsdiqlənib" },
    { status: "cancelled", label: "Ləğv edilib" },
  ];

  const statusDropdown = () => {
    setStatusOpen(!statusOpen);
  };

  useEffect(() => {
    if (demoModalData.status) {
      const targetStatus = statusList.find(
        (item) => item.status === demoModalData.status
      );
      setSelectedStatus(targetStatus);
    }
  }, [demoModalData.status]);

  const statusAddData = (item) => {
    updateModalState("status", item.status);
    setStatusOpen(false);
    setSelectedStatus(item);
  };

  const teacherNameDropdown = () => {
    setTeacherNameOpen(!teacherNameOpen);
  };
  const teacherNameAddData = (item) => {
    updateModalState("teacher", item._id);
    setTeacherNameOpen(false);
    setSelectedTeacherName(item);
  };

  const courseNameDropdown = () => {
    setClassNameOpen(!classNameOpen);
  };
  const courseNameAddData = (item) => {
    updateModalState("class", item.name);
    setClassNameOpen(false);
    setSelectedClassName(item);
  };

  useEffect(() => {
    dispatch(getTeachersAction());
  }, [dispatch]);

  const inputNameArr1 = [
    "fullName",
    "age",
    "parentName",
    "phone",
    "date",
    "time",
  ];

  const closeModal = () => {
    dispatch({
      type: DEMO_MODAL_ACTION_TYPE.GET_DEMO_MODAL,
      payload: { data: {}, openModal: false },
    });
  };

  useEffect(() => {
    dispatch(allCoursesAction());
  }, [dispatch]);

  useEffect(() => {
    if (demoModalData?._id && allCourses) {
      if (demoModalData.class) {
        setSelectedClassName(
          allCourses.filter((item) => item.name === demoModalData.class)[0]
        );
      }
    }
  }, [allCourses]);

  useEffect(() => {
    if (demoModalData?._id && teachers) {
      if (demoModalData.teacher) {
        setSelectedTeacherName(
          teachers.filter((item) => item._id === demoModalData.teacher._id)[0]
        );
      }
    }
  }, [teachers]);

  // useEffect(() => {
  //   if (demoModalData?._id && statusList) {
  //     if (demoModalData.staus) {
  //       setSelectedStatus(
  //         statusList.filter((item) => item.status === demoModalData.status)[0]
  //       );
  //     }
  //   }
  // }, [statusList]);

  const updateModalState = (keyName, value) => {
    dispatch({
      type: DEMO_MODAL_ACTION_TYPE.GET_DEMO_MODAL,
      payload: {
        data: {
          ...demoModalData,
          [keyName]: value,
        },
        openModal: true,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      fullName: demoModalData.fullName ? demoModalData.fullName : "",
    },
    validationSchema: ValidationSchema,
  });
  const setInputValue = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );

  return (
    <div className="create-update-modal-con demo-modal">
      <div className="create-update-modal">
        <div className="create-update-modal-head">
          <h2>{demoModalData?._id ? "Demo yenilə" : "Demo yarat"}</h2>
          <CloseBtn onClick={closeModal} />
        </div>

        <Box
          onSubmit={(e) => e.preventDefault()}
          component="form"
          sx={{
            width: 500,
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="create-update-modal-form">
            <RadioInput
              demoModalData={demoModalData}
              setInputValue={setInputValue}
              formik={formik}
              updateModalState={updateModalState}
            />
            <div className="input-couples">
              {inputNameArr1.map((name, index) => (
                <InputField
                  key={index}
                  inputName={name}
                  updateModalState={updateModalState}
                  formik={formik}
                  setInputValue={setInputValue}
                  demoModalData={demoModalData}
                />
              ))}
            </div>
            <TeacherLists
              selectedTeacherName={selectedTeacherName}
              teacherNameDropdown={teacherNameDropdown}
              teacherNameOpen={teacherNameOpen}
              setTeacherNameOpen={setTeacherNameOpen}
              teacherNameAddData={teacherNameAddData}
              teacherList={teachers}
            />

            <CoursesInput
              selectedClassName={selectedClassName}
              courseNameDropdown={courseNameDropdown}
              classNameOpen={classNameOpen}
              setClassNameOpen={setClassNameOpen}
              courseNameAddData={courseNameAddData}
              classList={allCourses}
            />
          </div>
        </Box>

        {demoModalData?._id ? (
          <div className="create-update-modal-btn-con demo ">
            <Status
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              statusDropdown={statusDropdown}
              statusOpen={statusOpen}
              setStatusOpen={setStatusOpen}
              statusAddData={statusAddData}
              statusList={statusList}
            />
            <SubmitBtn
              formik={formik}
              demoModalData={demoModalData}
              closeModal={closeModal}
              funcType="update"
            />
          </div>
        ) : (
          <SubmitBtn
            formik={formik}
            demoModalData={demoModalData}
            closeModal={closeModal}
            funcType="create"
          />
        )}
      </div>
    </div>
  );
};

export default DemoModal;
