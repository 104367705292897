import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TeacherFeedbackData from "./TeacherFeedback/TeacherFeedbackData";
import { StudentFeedbackData } from "./StudentFeedback/StudentFeedbackData";
import ParentFeedbackData from "./ParentFeedback/ParentFeedbackData";

const FeedbackData = ({
  feedbackPageNum,
  getPageNumberTeacher,
  getPageNumberStudent,
  getPageNumberParent,
}) => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/feedback/teacher" ? (
        <TeacherFeedbackData
          feedbackPageNum={feedbackPageNum}
          getPageNumber={getPageNumberTeacher}
        />
      ) : location.pathname === "/feedback/student" ? (
        <StudentFeedbackData
          feedbackPageNum={feedbackPageNum}
          getPageNumber={getPageNumberStudent}
        />
      ) : (
        <ParentFeedbackData
          feedbackPageNum={feedbackPageNum}
          getPageNumber={getPageNumberParent}
        />
      )}
    </>
  );
};

export default FeedbackData;
