
const Status = ({
  updatedResultData,
  setUpdatedResultData,
  futureLesson,
  user,
  setIsCancelled
}) => {
  const confirmedStatusList = [
    { status: "unviewed", label: "Baxılmayıb" },
    { status: "confirmed", label: "Təsdiqlənib" },
    { status: "cancelled", label: "Ləğv edilib" },
  ];

 


  const handleStatusClick = (status) => {
    setUpdatedResultData({ ...updatedResultData, status });
    if (status === "cancelled") {
      setIsCancelled(true);
    } else {
      setIsCancelled(false);
    }
  };
  return (
    <>
      {user?.role !== "teacher" && (
        <div className="modal-select">
          <ul>
            {confirmedStatusList.map((item, i) => (
              <li
                key={i}
                className={
                  item.status === updatedResultData.status
                    ? "active"
                    : futureLesson
                    ? "disabled"
                    : "none"
                }
                onClick={() => handleStatusClick(item.status)}
                id={item.status}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      )}
      
    </>
  );
};

export default Status;
