import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseBtn } from "../../../assets/icons/Icon.svg";
import moment from "moment";
import { RECEIPTS_MODAL_ACTION_TYPE } from "../../../redux/actions-type";
import InputField from "./components/Inputs/InputField";
import Status from "./components/Buttons/Status";
import SubmitBtn from "./components/Buttons/SubmitBtn";
import BranchField from "./components/Inputs/BranchField";
import { useFormik } from "formik";
import { ValidationSchema } from "./components/ValidationSchema/ValidationSchema";

const ReceiptsModal = () => {
  const dispatch = useDispatch();
  const { receiptsModalData } = useSelector((state) => state.receiptsModal);

  const inputNameArr1 = [
    "productName",
    "appointment",
    "productCount",
    "confirmedProductCount",
    "initialAmount",
    "principalAmount",
  ];

  const formik = useFormik({
    initialValues: {
      creator: receiptsModalData?.creator || "",
      branchName: receiptsModalData?.branchName || "",
      productName: receiptsModalData?.productName || "",
      productCount: receiptsModalData?.productCount || "",
      confirmedProductCount: receiptsModalData?.confirmedProductCount || "",
      initialAmount: receiptsModalData.initialAmount || "",
      appointment: receiptsModalData?.appointment || "",
      // initialAmount: receiptsModalData?.initialAmount || "",
      principalAmount: receiptsModalData?.principalAmount || "",
      note: receiptsModalData?.note || "",
      status: receiptsModalData?.status || "",
    },
    validationSchema: ValidationSchema,
  });

  const setInputValue = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );

  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branchOpen, setBranchOpen] = useState(false);
  const selectedBranchList = [
    { key: "narimanov", label: "Nərimanov" },
    { key: "yasamal", label: "Yasamal" },
  ];

  const branchDropdown = () => {
    setBranchOpen(!branchOpen);
  };

  const branchAddData = (item) => {
    updateModalState("branchName", item.key);
    setSelectedBranch(item);
    setBranchOpen(false);
  };

  const updateModalState = (keyName, value) => {
    setInputValue(keyName, value);
    dispatch({
      type: RECEIPTS_MODAL_ACTION_TYPE.GET_RECEIPTS_MODAL,
      payload: {
        data: { ...receiptsModalData, [keyName]: value },
        openModal: true,
      },
    });
  };
  const closeModal = () => {
    dispatch({
      type: RECEIPTS_MODAL_ACTION_TYPE.GET_RECEIPTS_MODAL,
      payload: { data: {}, openModal: false },
    });
  };

  useEffect(() => {
    if (receiptsModalData?._id) {
      if (receiptsModalData.branchName) {
        setSelectedBranch(
          selectedBranchList.filter(
            (item) => item.key === receiptsModalData.branchName
          )[0]
        );
      }
    }
  }, []);

  return (
    <div className="create-update-modal-con teacher-modal">
      <div className="create-update-modal">
        <div className="create-update-modal-head">
          <h2>{receiptsModalData?._id ? "Məhsulu yenilə" : "Məhsul yarat"}</h2>
          <CloseBtn onClick={closeModal} />
        </div>

        <Box
          onSubmit={(e) => e.preventDefault()}
          component="form"
          sx={{
            width: 500,
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="create-update-modal-form">
            <InputField
              inputName={"creator"}
              formik={formik}
              setInputValue={setInputValue}
              updateModalState={updateModalState}
              receiptsModalData={receiptsModalData}
            />
            <BranchField
              selectedBranchList={selectedBranchList}
              branchDropdown={branchDropdown}
              branchAddData={branchAddData}
              branchOpen={branchOpen}
              selectedBranch={selectedBranch}
            />
            <div className="input-couples">
              {inputNameArr1.map((name, index) => (
                <InputField
                  key={index}
                  inputName={name}
                  formik={formik}
                  setInputValue={setInputValue}
                  updateModalState={updateModalState}
                  receiptsModalData={receiptsModalData}
                />
              ))}
            </div>
            <InputField
              inputName={"note"}
              formik={formik}
              setInputValue={setInputValue}
              updateModalState={updateModalState}
              receiptsModalData={receiptsModalData}
            />
            {receiptsModalData?._id && (
              <div className="create-update-modal-btn-con ">
                <Status
                  receiptsModalData={receiptsModalData}
                  updateModalState={updateModalState}
                />
              </div>
            )}
          </div>
        </Box>

        {receiptsModalData?._id ? (
          <div
            className="create-update-modal-btn-con"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <SubmitBtn
              formik={formik}
              receiptsModalData={receiptsModalData}
              funcType="update"
              closeModal={closeModal}
            />
          </div>
        ) : (
          <SubmitBtn
            formik={formik}
            receiptsModalData={receiptsModalData}
            funcType="create"
            closeModal={closeModal}
          />
        )}

        {receiptsModalData?._id && (
          <div className="joined-time">
            Qoşuldu: {moment(receiptsModalData.createdAt).format("YYYY.MM.DD")}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReceiptsModal;
