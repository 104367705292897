import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function InputField({
  formik,
  receiptsModalData,
  inputName,
  updateModalState,
}) {
  const [shrink, setShrink] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { user } = useSelector((state) => state.user);

  const inputData = [
    {
      inputName: "creator",
      label: "Tələb edən",
      type: "text",
      marginTop: "24px",
      marginBottom: "0",
      inputValue: receiptsModalData[inputName]?.fullName || "",
    },
    {
      inputName: "productName",
      label: "Məhsul adı",
      type: "text",
      marginTop: "24px",
      marginBottom: "0",
      inputValue: receiptsModalData[inputName] || "",
    },
    {
      inputName: "productCount",
      label: "Tələb olunan məhsul sayı",
      type: "number",
      marginTop: "24px",
      marginBottom: "0",
      inputValue: receiptsModalData[inputName] || "",
    },
    {
      inputName: "confirmedProductCount",
      label: "Təsdiqlənən məhsul sayı",
      type: "number",
      marginTop: "24px",
      marginBottom: "0",
      inputValue: receiptsModalData[inputName] || "",
    },
    {
      inputName: "appointment",
      label: "Təyinat",
      type: "text",
      marginTop: "24px",
      marginBottom: "0",
      inputValue: receiptsModalData[inputName] || "",
    },
    {
      inputName: "initialAmount",
      label: "İlkin dəyər",
      type: "number",
      marginTop: "24px",
      marginBottom: "0",
      inputValue: receiptsModalData[inputName] || "",
    },
    {
      inputName: "principalAmount",
      label: "Əsas dəyər",
      type: "text",
      marginTop: "24px",
      marginBottom: "0",
      inputValue: receiptsModalData[inputName] || "",
    },
    {
      inputName: "note",
      label: "Qeyd",
      type: "text",
      marginTop: "24px",
      marginBottom: "0",
      inputValue: receiptsModalData[inputName] || "",
    },
  ];

  useEffect(() => {
    if (inputName === "creator") setIsDisabled(true);

    if (
      inputName === "principalAmount" ||
      inputName === "confirmedProductCount"
    ) {
      if (user.role === "teacher" || user.role === "admin") {
        setIsDisabled(true);
      } else if (user.role === "worker") {
        const isExistPosition = user.positions.find(
          (position) => position.key === "accounting-officer"
        );

        if (!isExistPosition) setIsDisabled(true);
      }
    }
  }, [inputName]);
  return (
    <div
      className={
        inputData.find((item) => item.inputName === inputName)?.className || ""
      }
    >
      <TextField
        sx={{
          "& input": {
            fontSize: "12px",
            paddingRight: inputData.find((item) => item.inputName === inputName)
              ?.paddingRight,
          },
          marginTop: inputData.find((item) => item.inputName === inputName)
            .marginTop,
          marginBottom: inputData.find((item) => item.inputName === inputName)
            ?.marginBottom,
        }}
        disabled={isDisabled}
        InputLabelProps={{
          shrink: inputData.find((item) => item.inputName === inputName)
            .inputValue
            ? true
            : shrink,
          style: {
            fontSize: "12px",
            color: "#3F3F3F",
            marginBottom: inputData.find((item) => item.inputName === inputName)
              .marginBottom,
          },
        }}
        fullWidth
        id={inputName}
        name={inputName}
        type={inputData.find((item) => item.inputName === inputName).type}
        label={inputData.find((item) => item.inputName === inputName).label}
        value={
          inputData.find((item) => item.inputName === inputName)?.inputValue
        }
        onWheel={(e) => e.target.blur()}
        onChange={(e) => {
          updateModalState(inputName, e.target.value);
        }}
        onBlur={(e) => {
          formik.setFieldTouched(inputName, true);
          setShrink(!!e.target.value);
        }}
        onFocus={() => setShrink(true)}
      />

      {formik.errors[inputName] && formik.touched[inputName] && (
        <small className="validation-err-message">
          {formik.errors[inputName]}
        </small>
      )}
    </div>
  );
}
