import React from "react";

export default function RadioInput({
  demoModalData,
  setInputValue,
  formik,
  updateModalState,
}) {
  const changeSector = (e, value) => {
    updateModalState("sector", value);
    setInputValue("sector", "AZ");
  };

  return (
    <div>
      <label className="radio-sector-title">Tədris dili</label>
      <div className="radio-sector-con department">
        <label>
          <input
            type="checkbox"
            name="sector"
            checked={demoModalData.sector === "AZ"}
            onChange={(e) => changeSector(e, "AZ")}
          />
          AZ
        </label>

        <label>
          <input
            type="checkbox"
            name="language"
            checked={demoModalData?.sector === "RU"}
            onChange={(e) => changeSector(e, "RU")}
          />
          RU
        </label>

        <label>
          <input
            type="checkbox"
            name="language"
            checked={demoModalData?.sector === "EN"}
            onChange={(e) => changeSector(e, "EN")}
          />
          EN
        </label>
      </div>
      {formik.errors.sector && (
        <small className="validation-err-message sector">
          {formik.errors.sector}
        </small>
      )}
    </div>
  );
}
