import { useEffect, useState } from "react";
import Loading from "../../../globalComponents/Loading/Loading";
import ReceiptsCard from "./ReceiptsCard";
import { useSelector } from "react-redux";
import { Pagination } from "antd";
import MoreModal from "../../../globalComponents/MoreModal/MoreModal";

const ReceiptsData = ({ getPageNumber, receiptPageNum }) => {
  const tableHead = [
    "Tələb edən",
    "Məhsulun adı",
    "Tələb olunan məhsul sayı",
    "Təsdiqlənən məhsul sayı",
    "İlkin dəyər",
    "Əsas dəyər",
    "Status",
    "",
    "",
  ];

  const { receipts, totalPages, loading } = useSelector(
    (state) => state.receiptsPagination
  );
  const [openMoreModal, setOpenMoreModal] = useState(false);

  useEffect(() => {
    if (openMoreModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [openMoreModal]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {openMoreModal && (
            <MoreModal
              data={receipts}
              setOpenMoreModal={setOpenMoreModal}
              type="receipt"
            />
          )}
          <table className="details-table  student-table">
            <thead>
              <tr>
                {tableHead.map((head, i) => (
                  <th key={i}>{head}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {receipts?.map((receipt, i) => (
                <ReceiptsCard
                  key={i}
                  data={receipt}
                  mode="desktop"
                  setOpenMoreModal={setOpenMoreModal}
                  cellNumber={i + 1 + (receiptPageNum - 1) * 10}
                />
              ))}
            </tbody>
          </table>

          <div className="details-list-tablet with-more">
            {receipts?.map((receipt, i) => (
              <ReceiptsCard
                key={i}
                data={receipt}
                mode="tablet"
                setOpenMoreModal={setOpenMoreModal}
                cellNumber={i + 1 + (receiptPageNum - 1) * 10}
              />
            ))}
          </div>

          {totalPages > 1 && (
            <div className="pages-pagination">
              <Pagination
                current={receiptPageNum}
                defaultCurrent={1}
                total={totalPages * 10}
                onChange={getPageNumber}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ReceiptsData;
