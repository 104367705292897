import {useState,react} from "react"
import { useSelector } from "react-redux";
import WorkerCard from "./WorkersCard";
import Loading from "../../../globalComponents/Loading/Loading";
import { Pagination } from "antd";
import MoreModal from "../../../globalComponents/MoreModal/MoreModal";

const WorkersData = ({workersPageNum,getPageNumber}) => {
  const { workers, loading,totalPages } = useSelector((state) => state.workersPagination);

  const [openMoreModal, setOpenMoreModal] = useState(false);
  const tableHead = [
    { id: 1, label: "Əməkdaşın adı" },
    { id: 2, label: "Şöbə" },
    { id: 3, label: "Nömrə" },
    { id: 4, label: "Vəzifə" }, 
    {id:5,label:""},
    { id: 7, label: "" },
  ];



  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
        {openMoreModal && (
            <MoreModal
              data={workers}
              setOpenMoreModal={setOpenMoreModal}
              type="worker"
            />
          )}
          <table className="details-table student-table">
            <thead>
              <tr>
                {tableHead.map((head, i) => (
                  <th key={i}>{head.label}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {workers?.map((worker, i) => (
                <WorkerCard
                  key={i}
                  data={worker}
                  mode="desktop"
                  setOpenMoreModal={setOpenMoreModal}
                  cellNumber={i + 1}
                />
              ))}
            </tbody>
          </table>

          <div className="details-list-tablet with-more">
            {workers?.map((worker, i) => (
              <WorkerCard
                key={i}
                data={worker}
                mode="tablet"
                setOpenMoreModal={setOpenMoreModal}
                cellNumber={i + 1}
              />
            ))}
          </div>
            {totalPages > 1 &&
             <div className="pages-pagination">
             <Pagination
               current={workersPageNum}
               defaultCurrent={1}
               total={totalPages * 10}
               onChange={getPageNumber}
             />
           </div>
            }
        </>
      )}
    </>
  );
};

export default WorkersData;
