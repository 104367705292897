import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {updateDemoAction,createDemoAction} from "../../../../../redux/actions/demoActions"
import { SEARCH_VALUES_ACTION_TYPES} from "../../../../../redux/actions-type";
import LoadingBtn from "../../../../Loading/components/LoadingBtn/LoadingBtn";

const SubmitBtn = ({ formik, demoModalData, funcType, closeModal }) => {
  const dispatch = useDispatch();
  const { demoModalLoading } = useSelector((state) => state.demoModal);
  const [isDisabled, setIsDisabled] = useState(() => {
    if (funcType === "update") {
      return false;
    } else {
      return true;
    }
  });

  useEffect(() => {
    setIsDisabled(() => {
      if (funcType === "update") {
        if (
          Object.keys(formik.errors).length === 0 &&
          demoModalData?.fullName
        ) {
          return false;
        } else if (
          Object.keys(formik.errors).length === 1 &&
          formik.errors.password === "Bu xana tələb olunur."
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (formik.isValid && demoModalData?.fullName) {
          return false;
        } else {
          return true;
        }
      }
    });
  }, [formik.errors]);
  
  const demoCreate = () => {
    if (demoModalData?._id) {
      dispatch(updateDemoAction(demoModalData?._id, demoModalData));
    } else {
      dispatch({
        type: SEARCH_VALUES_ACTION_TYPES.DEMO_SEARCH_VALUE,
        payload: "",
      });
      dispatch(
        createDemoAction({
          ...demoModalData,
        })
      );
    }
  };



  return (
    // <div>
    <div className="create-update-modal-btn demo-btn ">
      <button disabled={ demoModalLoading}   onClick={demoCreate}>
      {demoModalLoading ? (
        <LoadingBtn />
      ) : funcType === "update" ? (
        "Yenilə"
      ) : (
        "Yarat"
      )}
      </button>
    </div>
  // </div>
  );
};

export default SubmitBtn;
