import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWorkersPaginationAction } from "../../redux/actions/workerActions";
import { WORKERS_MODAL_ACTION_TYPE } from "../../redux/actions-type";
import WorkersData from "./components/WorkersData";
import GlobalHead from "../../globalComponents/GlobalHead/GlobalHead";

const WorkersPage = () => {
  const dispatch = useDispatch();

  const { lastPage } = useSelector((state) => state.workersPagination);
  const [workersPageNum, setWorkersPageNum] = useState(1);
  const { workerSearchValues } = useSelector((state) => state.searchValues);

  const getPageNumber = (pageNumber) => {
    setWorkersPageNum(pageNumber);
    if (workerSearchValues) {
      dispatch(getWorkersPaginationAction(pageNumber, workerSearchValues));
    } else {
      dispatch(getWorkersPaginationAction(pageNumber, ""));
    }
  };

  const searchData = (e) => {
    e.preventDefault();
    dispatch(getWorkersPaginationAction(1, workerSearchValues));
    setWorkersPageNum(1);
  };

  useEffect(() => {
    if (lastPage) {
      setWorkersPageNum(lastPage);
    }
  }, [lastPage]);

  const openModal = () => {
    dispatch({
      type: WORKERS_MODAL_ACTION_TYPE.GET_WORKERS_MODAL,
      payload: { data: {}, openModal: true },
    });
  };

  useEffect(() => {
    if (workerSearchValues) {
      dispatch(getWorkersPaginationAction(1, workerSearchValues));
    } else {
      dispatch(getWorkersPaginationAction(1, ""));
    }
  }, [dispatch]);

  return (
    <div className="details-page admins-page">
      <GlobalHead
        openModal={openModal}
        searchData={searchData}
        DATA_SEARCH_VALUE={"WORKER_SEARCH_VALUE"}
        dataSearchValues={workerSearchValues}
        statusType="worker"
      />
      <WorkersData
        workersPageNum={workersPageNum}
        getPageNumber={getPageNumber}
      />
    </div>
  );
};

export default WorkersPage;
