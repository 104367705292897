import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const BranchDropdown = () => {
  const branches = [
    {
      url: "https://nenteskedu.edinify.com",
      name: "Nərimanov",
    },
    {
      url: "https://enteskedu.edinify.com",
      name: "Yasamal",
    },
  ];

  const currentBranch = branches.find(
    (branch) => branch.url === window.location.origin
  );

  const handleChange = (e) => {
    window.location.href = e.target.value;
  };

  return (
    <>
      <Box sx={{ minWidth: 120, margin: " auto 15px auto 5px " }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Filiallar</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentBranch?.name}
            label="Filiallar"
            onChange={handleChange}
          >
            {branches.map((branch, i) => (
              <MenuItem key={i} value={branch?.url}>
                {branch?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default BranchDropdown;
