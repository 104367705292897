import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Routing } from "./routing";
import TeacherModal from "./globalComponents/Modals/TeacherModal/TeacherModal";
import AdminModal from "./globalComponents/Modals/AdminModal/AdminModal";
import WorkerModal from "./globalComponents/Modals/WorkerModal/WorkerModal";
import { CourseModal } from "./globalComponents/Modals/CourseModal/CourseModal";
import { StudentModal } from "./globalComponents/Modals/StudentModal/StudentModal";
import { ExpensesModal } from "./globalComponents/Modals/ExpensesModal/ExpensesModal";
import { IncomesModal } from "./globalComponents/Modals/IncomesModal/IncomesModal";
import { BonusModal } from "./globalComponents/Modals/BonusModal/BonusModal";
import { FeedbackModal } from "./globalComponents/Modals/FeedbackModal/FeedbackModal";
import { FineModal } from "./globalComponents/Modals/FineModal/FineModal";
import DemoModal from "./globalComponents/Modals/DemoModal/DemoModal";
import ParentFeedbackModal from "./globalComponents/Modals/ParentFeedbackModal/ParentFeedbackModal"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SyllabusModal from "./globalComponents/Modals/SyllabusModal/SyllabusModal";
import ReceiptsModal from "./globalComponents/Modals/ReceiptsModal/ReceiptsModal";


function App() {
  const { coursesOpenModal } = useSelector((state) => state.coursesModal);
  const { studentsOpenModal } = useSelector((state) => state.studentsModal);
  const { teachersOpenModal } = useSelector((state) => state.teachersModal);
  const { adminsOpenModal } = useSelector((state) => state.adminsModal);
  const { workersOpenModal } = useSelector((state) => state.workersModal);
  const { expensesOpenModal } = useSelector((state) => state.expensesModal);
  const { incomesOpenModal } = useSelector((state) => state.incomesModal);
  const { bonusOpenModal } = useSelector((state) => state.bonusModal);
  const { feedbackOpenModal } = useSelector((state) => state.feedbackModal);
  const { fineOpenModal } = useSelector((state) => state.fineModal);
  const { openMainPageModal } = useSelector((state) => state.modalLesson);
  const { demoOpenModal } = useSelector((state) => state.demoModal);
  const {parentOpenModal} = useSelector(state=>state.parentFeedback)
  const {syllabusOpenModal} = useSelector(state=>state.syllabusModal)
  const {receiptsOpenModal} = useSelector(state=>state.receiptsModal)



  useEffect(() => {
    if (bonusOpenModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [bonusOpenModal]);

  useEffect(() => {
    if (coursesOpenModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [coursesOpenModal]);

  useEffect(() => {
    if (expensesOpenModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [expensesOpenModal]);

  useEffect(() => {
    if (feedbackOpenModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [feedbackOpenModal]);

  useEffect(() => {
    if (fineOpenModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [fineOpenModal]);

  useEffect(() => {
    if (incomesOpenModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [incomesOpenModal]);

  useEffect(() => {
    if (studentsOpenModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [studentsOpenModal]);

  useEffect(() => {
    if (teachersOpenModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [teachersOpenModal]);

  useEffect(() => {
    if (openMainPageModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [openMainPageModal]);

  useEffect(() => {
    if (demoOpenModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [demoOpenModal]);

  useEffect(() => {
    if (parentOpenModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [parentOpenModal]);

  useEffect(() => {
    if (syllabusOpenModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [syllabusOpenModal]);


  useEffect(() => {
    if (receiptsOpenModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [receiptsOpenModal]);
  return (
    <div className="App">
      <Routing />
      {coursesOpenModal && <CourseModal />}
      {studentsOpenModal && <StudentModal />}
      {teachersOpenModal && <TeacherModal />}
      {adminsOpenModal && <AdminModal />}
      {workersOpenModal && <WorkerModal />}
      {expensesOpenModal && <ExpensesModal />}
      {incomesOpenModal && <IncomesModal />}
      {bonusOpenModal && <BonusModal />}
      {feedbackOpenModal && <FeedbackModal />}
      {fineOpenModal && <FineModal />}
      {demoOpenModal && <DemoModal />}
      {parentOpenModal && <ParentFeedbackModal/>}
      {syllabusOpenModal && <SyllabusModal/>}
      {receiptsOpenModal && <ReceiptsModal/>}
      <ToastContainer />
    </div>
  );
}

export default App;