import { TextField } from "@mui/material";
import moment from "moment";
import {  useState } from "react";

export default function InputField({
  formik,
  demoModalData,
  inputName,
  updateModalState
}) {
  const [shrink, setShrink] = useState(false);
  const inputData = [
    {
      inputName: "fullName",
      label: "Ad soyad",
      type: "text",
      marginTop: "24px",
      marginBottom: "0",
      inputValue: demoModalData[inputName] || "",
    },
    {
        inputName: "age",
      label: "Tələbə yaşı",
      type: "number",
      marginTop: "24px",
      marginBottom: "0",
      inputValue: demoModalData[inputName] || "", 
    },
    {
        inputName: "parentName",
        label: "Valideyn adı",
        type: "text",
        marginTop: "24px",
        marginBottom: "0",
        inputValue: demoModalData[inputName] || "",
      },
    {
        inputName: "phone",
        label: "Əlaqə nömrəsi",
        type: "tel",
        marginTop: "24px",
        marginBottom: "0",
        inputValue: demoModalData[inputName] || "",
    },
    {
        inputName: "date",
        label: "Tarix",
        type: "date",
        marginTop: "24px",
        marginBottom: "0",
        inputValue: (demoModalData[inputName] && inputName === "date")
          ? moment(demoModalData[inputName]).format("YYYY-MM-DD")
          : "",
      },
      {
        inputName: "time",
        label: "Dərs saat",
        type: "time",
        marginTop: "24px",
        marginBottom: "0",
        inputValue: demoModalData[inputName] || "",
      },
  ];

  return (
    <div>
     <TextField  sx={{
          "& input": { fontSize: "12px",
        paddingRight: inputData.find((item) => item.inputName === inputName)?.paddingRight },
          marginTop: inputData.find((item) => item.inputName === inputName).marginTop,
        }}
        InputLabelProps={{
            shrink:
              inputName === "date" || inputName=== "time"
                ? true
                : (inputData.find((item) => item.inputName === inputName)
                    .inputValue
                ? true
                : shrink),
            style: {
              fontSize: "12px",
              color: "#3F3F3F",
              marginBottom: inputData.find((item) => item.inputName === inputName)
                .marginBottom,
            },
          }}
        fullWidth
        id={inputName}
        name={inputName}
        type={inputData.find((item) => item.inputName === inputName).type}
        label={inputData.find((item) => item.inputName === inputName).label}
        value={
          inputData.find((item) => item.inputName === inputName)?.inputValue
        }
        onChange={(e)=>{
          updateModalState(inputName,e.target.value)
          // setInputValue(inputName,e.target.value)
        }}
        onBlur={(e) => {
          formik.setFieldTouched(inputName, true);
          setShrink(!!e.target.value);
        }}
        onFocus={() => setShrink(true)}
        />
    </div>
  );
}
