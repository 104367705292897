import { TextField } from "@mui/material";
import { useState } from "react";

const StudentLists = ({
  selectedStudentName,
  studentNameOpen,
  studentNameDropdown,
  studentNameAddData,
  students,
  setStudentNameOpen,
  setSelectedStudentName,
}) => {
  const [searchedValue, setSearcherValue] = useState("");
  const changeOpenDropdown = () => {
    setStudentNameOpen(!studentNameOpen);
  };

  const filteredStudents = students?.students?.filter((student) =>
    student.fullName.toLowerCase().includes(searchedValue.toLowerCase())
  );

  const searchData = (e) => {
    setSearcherValue(e.target.value);
    setSelectedStudentName("");
    setStudentNameOpen(true);
  };
  return (
    <>
      <div className="class-input">
        <div className="class-field">
          <TextField
            sx={{
              "& input": {
                fontSize: "12px",
                marginRight: "32px",
              },
              marginTop: "20px",
            }}
            InputLabelProps={{
              style: { fontSize: "12px", color: "#3F3F3F" },
            }}
            fullWidth
            label="Tələbə  adı"
            name="class"
            autoComplete="off"
            value={
              selectedStudentName ? selectedStudentName.fullName : searchedValue
            }
            onChange={(e) => searchData(e)}
            onClick={studentNameDropdown}
          />
          <div className="dropdown-icon">
            <div onClick={changeOpenDropdown}>
              <svg
                className={!studentNameOpen ? "down" : "up"}
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.92 9.4502L13.4 15.9702C12.63 16.7402 11.37 16.7402 10.6 15.9702L4.07999 9.4502"
                  stroke="#5D5D5D"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>

        <ul
          className={`create-update-modal-dropdown where-coming ${
            studentNameOpen ? "active" : ""
          }`}
        >
          {filteredStudents?.map((item, i) => {
            return (
              <li key={i} onClick={() => studentNameAddData(item)}>
                <h4>{item.fullName}</h4>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default StudentLists;
