import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { ReactComponent as CheckIcon } from "../../../../../../assets/icons/Checkbox.svg";
import PositionInput from "./PositionInput";
import { useCustomHook } from "../../../../../GlobalFunctions/globalFunctions";
import DropdownIcon from "../../DropdownIcon";

const PositionList = ({ formik, updateModalState, modalData }) => {
  const { workerPositionList } = useCustomHook();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [positionErrMessage, setPositionErrMessage] = useState(false);



  useEffect(() => {
    if (modalData?._id) {
      if (modalData.positions) {
        setSelectedPosition(
          workerPositionList.filter(
            (item) => item.key === modalData.positions
          )[0]
        );
      }
    }
  },[]);

  const deleteClass = (index) => {
    if (modalData?.positions?.length === 1) {
      updateModalState("positions", []);
    } else {
      const positionData = [...modalData.positions];
      positionData.splice(index, 1);
      updateModalState("positions", positionData);
    }
  };
  const addPosition = () => {
    if (modalData.positions) {
      if (
        modalData?.positions?.find((item) => item.key === selectedPosition.key)
      ) {
        setPositionErrMessage(true);
      } else {
        const positionData = [
          ...modalData?.positions,
          { key: selectedPosition.key, name: selectedPosition.name },
        ];
        setPositionErrMessage(false);
        updateModalState("positions", positionData);
      }
    } else {
      const positionData = [
        { key: selectedPosition.key, name: selectedPosition.name },
      ];
      setPositionErrMessage(false);
      updateModalState("positions", positionData);
    }
    setSelectedPosition("");
    setOpenDropdown(false);
  };

  return (
    <div>
      <div className="dropdown-input courses">
        <div className="left">
          <div className="input-box">
            <TextField
              sx={{
                "& input": { fontSize: "12px", marginRight: "32px" },
                marginTop: "24px",
              }}
              InputLabelProps={{
                style: { fontSize: "12px", color: "#3F3F3F" },
              }}
              fullWidth
              label="Vəzifələr"
              autoComplete="off"
              value={selectedPosition?.name || ""}
              disabled
              onClick={() => setOpenDropdown(!openDropdown)}
            />

            <DropdownIcon
              setOpenDropdown={setOpenDropdown}
              openDropdown={openDropdown}
            />
          </div>

          <ul className={`dropdown-body ${openDropdown ? "active" : ""}`}>
            {workerPositionList
              ?.filter((item) => item.key !== "workers")
              ?.map((item, index) => (
                <li key={index} onClick={() => setSelectedPosition(item)}>
                  {modalData?.positions?.find((obj) => obj.key === item.key) ? (
                    <CheckIcon />
                  ) : null}
                  <h4>{item.name}</h4>
                </li>
              ))}
          </ul>
        </div>

        <div className="right">
          <button
            disabled={!selectedPosition}
            onClick={() => addPosition()}
            className="add-class"
          >
            <AiOutlinePlusCircle />
          </button>
        </div>
      </div>

      {formik.errors.position && formik.touched.position && (
        <small className="validation-err-message">
          {formik.errors.position}
        </small>
      )}

      <ul className="category-list courses-li">
        {positionErrMessage ? (
          <small className="category-error-message">
            Vəzifə artıq mövcuddur.
          </small>
        ) : null}

        {modalData?.positions?.map((item, index) => (
          <PositionInput
            key={index}
            index={index}
            data={item}
            deleteClass={deleteClass}
            modalData={modalData}
            updateModalState={updateModalState}
          />
        ))}
      </ul>
    </div>
  );
};

export default PositionList;
